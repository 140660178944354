// import { Box, Button, TextField } from "@mui/material";
// import { Formik } from "formik";
// import * as yup from "yup";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import Header from "../../components/Header";
// import { useEffect, useState } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import { useQuill } from "react-quilljs";
// import "quill/dist/quill.snow.css";

// const modules = {
//   toolbar: [
//     [{ header: [1, 2, 3, false] }],
//     [{ font: [] }],
//     [{ size: ["small", false, "large", "huge"] }],
//     ["bold", "italic", "underline", "strike"],
//     [{ color: [] }, { background: [] }],
//     [{ align: [] }],
//     ["blockquote", "code-block"],
//     [{ list: "ordered" }, { list: "bullet" }],
//     ["link", "image", "video"],
//     ["clean"],
//   ],
// };

// // Helper to check if data is in Delta format
// const isDeltaFormat = (data) => {
//   return typeof data === "object" && data !== null && Array.isArray(data.ops);
// };

// const EditPost = () => {
//   const isNonMobile = useMediaQuery("(min-width:600px)");
//   const [value, setValue] = useState("");
//   const [titleValue, setTitleValue] = useState("");
//   const location = useLocation();
//   const { title = "", body = "", id } = location.state || {};
//   const navigate = useNavigate();
//   const notify = (x) => toast(x);
//   const notifyError = (x) => toast.error(x);
//   const { quill, quillRef } = useQuill({ modules }); // Hook to initialize Quill

//   useEffect(() => {
//     if (quill) {
//       if (body) {
//         console.log("Body received for prepopulation:", body);

//         if (isDeltaFormat(body)) {
//           quill.setContents(body); // Use Delta directly
//         } else if (typeof body === "string") {
//           try {
//             const delta = quill.clipboard.convert(body); // Convert HTML to Delta
//             quill.setContents(delta);
//           } catch (error) {
//             console.warn(
//               "Failed to convert HTML to Delta. Falling back to raw HTML:",
//               error
//             );
//             quill.clipboard.dangerouslyPasteHTML(body); // Fallback to HTML injection
//           }
//         } else {
//           console.warn("Unsupported body format:", body);
//         }
//       } else {
//         console.warn("No body content found for prepopulation.");
//       }

//       // Listen for editor changes
//       quill.on("text-change", () => {
//         setValue(quill.root.innerHTML); // Update editor content state
//       });
//     }
//   }, [quill, body]);

//   // Custom toolbar configuration

//   const handleFormSubmit = async (event) => {
//     event.preventDefault();

//     if (!quill) {
//       console.error("Quill instance is not initialized.");
//       return;
//     }

//     // Capture content from the editor
//     const editorContent = quill.getContents(); // Delta format
//     const editorHTML = quill.root.innerHTML; // HTML format

//     console.log("Editor content (Delta):", editorContent);
//     console.log("Editor content (HTML):", editorHTML);

//     // Prepare form data
//     const formData = {
//       id: id, // ID from location.state
//       title: titleValue || title, // Updated title or fallback to original
//       body: JSON.stringify(editorContent), // Save Delta format as a string
//     };

//     console.log("Form data to submit:", formData);

//     try {
//       const response = await fetch(
//         "https://us-central1-argon-fx-383518.cloudfunctions.net/editPost", // Replace with your Cloud Function URL
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(formData),
//         }
//       );

//       if (response.ok) {
//         console.log("Form submitted successfully!");
//         notify("Post edited successfully!");
//         navigate("/news");
//       } else {
//         const errorText = await response.text();
//         console.error("Error submitting form:", errorText);
//         notifyError(`Error: ${errorText}`);
//       }
//     } catch (error) {
//       console.error("Error during submission:", error);
//       notifyError("Error: " + error.message + " [SE-NEWS]");
//     }
//   };

//   return (
//     <Box m="20px">
//       <Header title="Create post" subtitle="Create a new post" />
//       <Box m="50px" minHeight={"500px"}>
//         <TextField
//           fullWidth
//           variant="filled"
//           type="text"
//           label="Title"
//           name="title"
//           placeholder={title}
//           defaultValue={title}
//           onChange={(e) => setTitleValue(e.target.value)}
//           value={titleValue.value}
//           sx={{ gridColumn: "span 2" }}
//         />
//         <div style={{ height: 300 }} ref={quillRef} />
//         <Box m="20px" display={"flex"} justifyContent={"end"}>
//           <Button
//             onClick={handleFormSubmit}
//             color="secondary"
//             variant="contained"
//           >
//             Publish Changes
//           </Button>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default EditPost;

import { Box, Button, TextField } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    [{ font: [] }],
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

const isDeltaFormat = (data) => {
  return typeof data === "object" && data !== null && Array.isArray(data.ops);
};

const EditPost = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [value, setValue] = useState("");
  const [titleValue, setTitleValue] = useState("");
  const location = useLocation();
  const { title = "", body = "", id } = location.state || {};
  const navigate = useNavigate();
  const notify = (x) => toast(x);
  const notifyError = (x) => toast.error(x);
  const { quill, quillRef } = useQuill({ modules }); // Initialize Quill

  useEffect(() => {
    if (quill) {
      console.log("Quill initialized:", quill);

      if (body) {
        console.log("Body received for prepopulation:", body);

        let parsedBody;

        try {
          parsedBody = JSON.parse(body); // Parse the body as JSON
        } catch (error) {
          console.error("Failed to parse body as JSON:", error);
        }

        if (parsedBody && isDeltaFormat(parsedBody)) {
          quill.setContents(parsedBody); // Use parsed Delta format
        } else if (typeof body === "string") {
          try {
            const delta = quill.clipboard.convert(body); // Convert HTML to Delta
            console.log("Delta after conversion: ", delta);

            if (delta && delta.ops.length > 0) {
              quill.setContents(delta); // Use converted Delta
            } else {
              console.warn("Delta conversion failed, injecting HTML directly.");
              quill.clipboard.dangerouslyPasteHTML(body); // Fallback to direct HTML injection
            }
          } catch (error) {
            console.error("Error during Delta conversion:", error);
          }
        } else {
          console.warn("Unsupported body format:", body);
        }
      } else {
        console.warn("No body content found for prepopulation.");
      }

      quill.on("text-change", () => {
        setValue(quill.root.innerHTML); // Update state with editor content
      });
    } else {
      console.error("Quill instance not available!");
    }
  }, [quill, body]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!quill) {
      console.error("Quill instance is not initialized.");
      return;
    }

    const editorContent = quill.getContents(); // Capture Delta format

    const formData = {
      id: id,
      title: titleValue || title, // Use updated title or fallback to the original
      body: JSON.stringify(editorContent), // Store Delta format as a string
    };

    console.log("Submitting form data:", formData);

    try {
      const response = await fetch(
        "https://us-central1-argon-fx-383518.cloudfunctions.net/editPost",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        console.log("Form submitted successfully!");
        notify("Post edited successfully!");
        navigate("/news");
      } else {
        const errorText = await response.text();
        console.error("Failed to submit form:", errorText);
        notifyError(`Error: ${errorText}`);
      }
    } catch (error) {
      console.error("Error during submission:", error);
      notifyError("Error: " + error.message + " [SE-NEWS]");
    }
  };

  return (
    <Box m="20px">
      <Header title="Edit Post" subtitle="Update your post content" />
      <Box m="50px" minHeight={"500px"}>
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Title"
          name="title"
          placeholder={title}
          defaultValue={title}
          onChange={(e) => setTitleValue(e.target.value)}
          sx={{ gridColumn: "span 2" }}
        />
        <div style={{ height: 300 }} ref={quillRef} />
        <Box m="20px" display={"flex"} justifyContent={"end"}>
          <Button
            onClick={handleFormSubmit}
            color="secondary"
            variant="contained"
          >
            Save Changes
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default EditPost;
