import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, TextField, Checkbox } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { AuthContextProvider, UserAuth } from "../../context/AuthContext";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const Iframe = (props) => {
  const [showAdTypeSection, setShowAdTypeSection] = useState(false);
  const [campaigns, setCampaigns] = useState([]); // Store fetched campaigns
  const [isFormSelected, setIsFormSelected] = useState(false); // New state for form selection
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [enteredOtp, setEnteredOtp] = useState("");
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [verifiedNumber, setVerifiedNumber] = useState(""); // New state to store the locked verified number
  const [lockedNumber, setLockedNumber] = useState("");
  const [customerID, setCustomerID] = useState("");
  const customerSearchEnabled = [
    "VTL1000",
    "VTL1001",
    "VTL1002",
    "VTL1003",
    "VTL1297",
    "VTL1298",
    "VTL1299",
  ];
  const navigate = useNavigate();
  const Agent = props.agent;
  let Age = "";
  const notify = (x) => toast(x);
  const notifyError = (x) => toast.error(x);
  console.log(Agent);
  // const { user } = UserAuth();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    if (Agent && Agent.Status && Agent.Status !== "Active") {
      navigate("/");
    } else if (Agent && Agent.Status && Agent.Status == "Active") {
      fetchCampaignsbyPartnerID();
    }
  }, [Agent, navigate]);

  const fetchCampaignsbyPartnerID = async () => {
    let TempPid = Agent.PartnerID;

    let url =
      "https://us-central1-argon-fx-383518.cloudfunctions.net/getCampaigns";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ PartnerID: TempPid }),
    })
      .then((response) => response.json())
      .then((Data) => {
        console.log("fetch: ", Data);
        setCampaigns(Data); // Store fetched campaigns
        setShowAdTypeSection(Data && Data.length > 0); // Only show ad section if campaigns exist
      })
      .catch((error) => {
        console.error("Error fetching campaigns:", error);
      });
  };

  const filterCampaignsByAdType = (adType) => {
    return campaigns
      .filter((campaign) => campaign.AdType === adType)
      .map((campaign) => (
        <MenuItem key={campaign.ID} value={campaign.AdID}>
          {campaign.AdID}
        </MenuItem>
      ));
  };

  const generateCustomerID = () => {
    const randomNumber = Math.floor(100000000 + Math.random() * 900000000); // Generate 9-digit random number
    return `${randomNumber}LD`;
  };

  const needsOtp = () => {
    const otpPartnerIDs = ["VTL1296", "VTL1297"];
    // Check if Agent is defined before accessing its properties
    return (
      Agent &&
      (Agent.AgentID === "AGT00001" || otpPartnerIDs.includes(Agent.PartnerID))
    );
  };

  const verifyOtp = () => {
    if (enteredOtp === otp) {
      setIsOtpValid(true);
      notify("OTP verified successfully!");
    } else {
      notifyError("Invalid OTP. Please try again.");
    }
  };

  const handleSubmit = async (values) => {
    const otpPartnerIDs = ["VTL1296", "VTL1297"]; // Define the Partner IDs to skip OTP
    const CustID = generateCustomerID();

    if (
      Agent.AgentID !== "AGT00001" &&
      !otpPartnerIDs.includes(Agent.PartnerID)
    ) {
      // Skip OTP process and go directly to Place Order
      setLoading(true);
      values.mobile = values.mobile; // Use the mobile number directly

      // Additional form logic, such as campaign/partner data
      if (values.campaign !== "") {
        if (isFormSelected) {
          values.platform = "Form";
          values.partner = Agent.CompanyName;
        } else {
          const selectedCampaign = campaigns.find(
            (campaign) => campaign.AdID === values.campaign
          );
          if (selectedCampaign) {
            values.platform = selectedCampaign.Platform;
            values.partner = selectedCampaign.Partner;
          }
        }
      }

      // Populate values with Agent information
      // let address1,
      //   address2,
      //   zipcode,
      //   fname,
      //   lname,
      //   phone,
      //   mobile,
      //   email,
      //   present,
      //   moving,
      //   affiliate,
      //   agentEmail,
      //   storeID,
      //   salesID,
      //   accountID,
      //   affiliateID,
      //   salesAgentID,
      //   transID = "";

      if (Agent != undefined) {
        values.CustID = CustID;
        values.affiliateID = "422494";
        values.agentEmail = Agent.Email;
        values.agentID = Agent.AgentID;
        values.partnerID = Agent.PartnerID;
        // if (values.moving === "true") {
        //   values.moving = true;
        // } else {
        //   values.moving = false;
        // }
        // if (values.present === "true") {
        //   values.present = true;
        // } else {
        //   values.present = false;
        // }
      }

      try {
        const response = await fetch(
          "https://us-central1-argon-fx-383518.cloudfunctions.net/SalesOrderWriteBeta",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          }
        );
        console.log("Payload Sent: ", values);

        if (response.ok) {
          const payload = {
            zipCode: values.zipCode,
            values: values,
            URL: `https://retail.spectrum.com/address/agent-retail/localization?address1=${values.address1}&address2=${values.address2}&zip=${values.zipCode}&fname=${values.firstName}&lname=${values.lastName}&phone=${values.mobile}&mobile=${values.mobile}&email=${values.email}&affiliate=${values.affiliateID}&agentEmail=${values.agentEmail}&salesID=${Agent.AgentID}&present=${values.present}&moving=${values.moving}`,
          };
          let test =
            "https://cpartner.spectrum.com/address/agent-smbcpartner/localization?address1=2701&zip=91761&system=CSS&token=eyJzYWxlc0lEIjoiNjEwNjAvU0FOMDMzNDAiLCJyZWRpcmVjdGlvblRpbWUiOjE3MzEwMjY0MzN9";
          navigate("/serviceproviders", { state: payload });
        } else {
          notifyError("Error: Error submitting form [LE005]");
        }
      } catch (error) {
        console.error(error);
        notifyError(`Error: Error submitting form [LE006]: ${error}`);
      } finally {
        setLoading(false);
      }
      return;
    }

    // OTP process for other agents
    if (!otpSent) {
      // Step 1: Send OTP
      const generatedOtp = Math.floor(
        100000 + Math.random() * 900000
      ).toString();
      setOtp(generatedOtp);

      try {
        const response = await fetch(
          "https://us-central1-argon-fx-383518.cloudfunctions.net/sendOtp",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              phoneNumber: values.mobile,
              otp: generatedOtp,
            }),
          }
        );
        if (response.ok) {
          notify("OTP sent successfully!");
          setLockedNumber(values.mobile); // Lock the mobile number
          setOtpSent(true);
        } else {
          notifyError("Failed to send OTP. Please try again.");
        }
      } catch (error) {
        console.error("Error sending OTP:", error);
        notifyError("Error: Unable to send OTP.");
      }
    } else if (!isOtpValid) {
      // Step 2: Verify OTP
      if (enteredOtp === otp) {
        setIsOtpValid(true);
        notify("OTP verified successfully!");
      } else {
        notifyError("Invalid OTP. Please try again.");
      }
    } else {
      // Step 3: Place Order
      setLoading(true);
      console.log("veri: ", lockedNumber);
      console.log("mob: ", values.mobile);
      // Additional form logic
      if (values.campaign !== "") {
        if (isFormSelected) {
          values.platform = "Form";
          values.partner = Agent.CompanyName;
        } else {
          const selectedCampaign = campaigns.find(
            (campaign) => campaign.AdID === values.campaign
          );
          if (selectedCampaign) {
            values.platform = selectedCampaign.Platform;
            values.partner = selectedCampaign.Partner;
          }
        }
      }

      if (Agent != undefined) {
        values.CustID = CustID;
        values.affiliateID = "422494";
        values.agentEmail = Agent.Email;
        values.agentID = Agent.AgentID;
        values.partnerID = Agent.PartnerID;
        // if (values.moving === "true") {
        //   values.moving = true;
        // } else {
        //   values.moving = false;
        // }
        // if (values.present === "true") {
        //   values.present = true;
        // } else {
        //   values.present = false;
        // }
      }

      try {
        const response = await fetch(
          "https://us-central1-argon-fx-383518.cloudfunctions.net/SalesOrderWriteBeta",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(values),
          }
        );
        console.log("Payload Sent: ", values);

        if (response.ok) {
          const payload = {
            zipCode: values.zipCode,
            values: values,
            URL: `https://retail.spectrum.com/address/agent-retail/localization?address1=${values.address1}&address2=${values.address2}&zip=${values.zipCode}&fname=${values.firstName}&lname=${values.lastName}&phone=${values.mobile}&mobile=${values.mobile}&email=${values.email}&affiliate=${values.affiliateID}&agentEmail=${values.agentEmail}&salesID=${Agent.AgentID}&present=${values.present}&moving=${values.moving}`,
          };
          navigate("/serviceproviders", { state: payload });
        } else {
          notifyError("Error: Error submitting form [LE005]");
        }
      } catch (error) {
        console.error(error);
        notifyError(`Error: Error submitting form [LE006]: ${error}`);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleFormSubmit = (values) => {
    console.log(values);
  };
  const handleInputChange = (event) => {
    setCustomerID(event.target.value);
  };

  const handleCustomerSearch = async (setValues, values) => {
    try {
      if (!customerID.trim()) {
        notifyError("Customer ID cannot be empty");
        return;
      }

      const payload = {
        CustID: customerID,
        Camp: showAdTypeSection,
      };

      const response = await fetch(
        "https://us-central1-argon-fx-383518.cloudfunctions.net/getCustomerInfo",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const rawData = await response.json();
        const data = rawData[0];
        console.log("hmm: ", data);
        console.log("hmm2: ", Object.keys(data).length);
        console.log("hmm3: ", data.moving);

        if (data && Object.keys(data).length > 0) {
          notify("Customer found. Populating fields...");
          if (showAdTypeSection) {
            setValues({
              ...values,
              firstName: data.firstName || "",
              lastName: data.lastName || "",
              email: data.email || "",
              mobile: data.mobile || "",
              address1: data.address1 || "",
              address2: data.address2 || "",
              zipCode: data.zipCode || "",
              moving: data.moving ? "1" : "0",
              present: data.present ? "1" : "0",
              prevaddress1: data.prevaddress1 || "",
              prevApt: data.prevApt || "",
              prevZip: data.prevZip || "",
              adType: data.adType || "", // Pre-populate adType
              campaign: data.adID || "", // Pre-populate campaign
            });
          } else {
            setValues({
              ...values,
              firstName: data.firstName || "",
              lastName: data.lastName || "",
              email: data.email || "",
              mobile: data.mobile || "",
              address1: data.address1 || "",
              address2: data.address2 || "",
              zipCode: data.zipCode || "",
              moving: data.moving ? "1" : "0",
              present: data.present ? "1" : "0",
              prevaddress1: data.prevaddress1 || "",
              prevApt: data.prevApt || "",
              prevZip: data.prevZip || "",
            });
          }
        } else {
          notifyError("Customer not found. Please check the ID.");
        }
      } else {
        notifyError("Error fetching customer information.");
      }
    } catch (error) {
      console.error("Error in handleCustomerSearch:", error);
      notifyError("An error occurred while searching for the customer.");
    }
  };

  return (
    <div className="m-5">
      {/* <Header title="NEW ORDER" subtitle="Place order" /> */}
      {/* <Box>
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Customer Search"
          name="CustomerID-Search"
          sx={{ gridColumn: "span 1" }}
          value={customerID}
          onChange={handleInputChange}
        />
        <Button
          type="button"
          color="secondary"
          variant="contained"
          onClick={handleCustomerSearch}
        >
          Search
        </Button>
      </Box> */}
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validateOnBlur={true}
        validationSchema={yup.object().shape({
          firstName: yup.string().required("required"),
          lastName: yup.string().required("required"),
          email: yup.string().email("invalid email"),
          mobile: yup
            .string()
            .matches(phoneRegExp, "Phone number is not valid")
            .test("len", "Must be exactly 10 characters", (val) => {
              if (val) return val.toString().length === 10;
            })
            .required("required"),
          address1: yup.string().required("required"),
          zipCode: yup
            .string()
            .test("len", "Must be exactly 5 characters", (val) => {
              if (val) return val.toString().length === 5;
            })
            .required("required"),
          moving: yup.string().required("Selection required"),

          // Conditional validation for adType based on whether campaigns are available
          adType: yup.string().when([], {
            is: () => showAdTypeSection, // When campaigns are found, require AdType
            then: yup.string().required("You must select an Ad Type"),
            otherwise: yup.string().notRequired(),
          }),

          // Conditional validation for campaign based on whether "Form" is selected or not
          campaign: yup.string().when("adType", {
            is: (adType) => adType === "Form", // If "Form" is selected, require Customer ID instead
            then: yup.string().required("You must enter a Customer ID"),
            otherwise: yup.string().when([], {
              is: () => showAdTypeSection, // Require campaign only if adType section is shown (i.e., campaigns are found)
              then: yup.string().required("You must select a campaign"),
              otherwise: yup.string().notRequired(), // Campaign is not required when no campaigns are available
            }),
          }),
        })}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setValues,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              {/* <Header
                title="NEW ORDER"
                subtitle="Place order"
                sx={{ gridColumn: "span 2" }}
              /> */}

              <Box sx={{ gridColumn: "span 1" }}>
                <Header title="NEW ORDER" subtitle="Place order" />
              </Box>
              {customerSearchEnabled.includes(Agent?.PartnerID) && (
                <Box sx={{ gridColumn: "span 1", textAlign: "right" }}>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Customer Search"
                    name="CustomerID-Search"
                    sx={{ gridColumn: "span 1" }}
                    value={customerID}
                    onChange={handleInputChange}
                  />
                  <Button
                    type="button"
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      handleCustomerSearch(setValues);
                    }}
                    sx={{
                      gridColumn: "span 1",
                      marginTop: "-75px",
                      marginRight: "5px",
                    }}
                  >
                    Search
                  </Button>
                </Box>
              )}
              <Box sx={{ gridColumn: "span 2", textAlign: "right" }}></Box>
              {showAdTypeSection && (
                <>
                  <Box sx={{ gridColumn: "span 2" }}>
                    <FormLabel id="adType-label">Ad Type</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="adType"
                      name="adType"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setIsFormSelected(e.target.value === "Form"); // Set state based on whether "Form" is selected
                      }}
                      value={values.adType}
                      error={!!touched.adType && !!errors.adType}
                    >
                      <FormControlLabel
                        value="Messenger"
                        control={<Radio color="secondary" />}
                        label="Messenger"
                      />
                      <FormControlLabel
                        value="Incoming Call"
                        control={<Radio color="secondary" />}
                        label="Incoming call"
                      />
                      <FormControlLabel
                        value="Followup"
                        control={<Radio color="secondary" />}
                        label="Follow up"
                      />
                      <FormControlLabel
                        value="Form"
                        control={<Radio color="secondary" />}
                        label="Form"
                      />
                    </RadioGroup>
                    {touched.adType && errors.adType ? (
                      <div style={{ color: "red" }}>{errors.adType}</div>
                    ) : null}
                  </Box>

                  {isFormSelected ? (
                    <TextField
                      fullWidth
                      variant="filled"
                      label="Customer ID"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.campaign || ""}
                      name="campaign"
                      error={!!touched.campaign && !!errors.campaign}
                      helperText={touched.campaign && errors.campaign}
                    />
                  ) : (
                    showAdTypeSection && (
                      <Select
                        labelId="campaign-select-label"
                        id="campaign-select"
                        sx={{ height: "35px", marginTop: "20px" }}
                        value={values.campaign || ""}
                        onChange={handleChange}
                        name="campaign"
                        displayEmpty
                        error={!!touched.campaign && !!errors.campaign}
                      >
                        <MenuItem value="" disabled>
                          <em>Select a campaign</em>
                        </MenuItem>
                        {values.adType &&
                          filterCampaignsByAdType(values.adType)}
                      </Select>
                    )
                  )}
                </>
              )}

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="First Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                name="firstName"
                error={!!touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Last Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                name="lastName"
                error={!!touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Mobile Number"
                onBlur={handleBlur}
                onChange={(e) => {
                  if (!lockedNumber) {
                    handleChange(e); // Allow changes only if the number isn't locked
                  }
                }}
                value={lockedNumber || values.mobile}
                name="mobile"
                error={!!touched.mobile && !!errors.mobile}
                helperText={touched.mobile && errors.mobile}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Address 1"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address1}
                name="address1"
                error={!!touched.address1 && !!errors.address1}
                helperText={touched.address1 && errors.address1}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Apt / Suite / Lot"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address2}
                name="address2"
                error={!!touched.address2 && !!errors.address2}
                helperText={touched.address2 && errors.address2}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Zip code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.zipCode}
                name="zipCode"
                error={!!touched.zipCode && !!errors.zipCode}
                helperText={touched.zipCode && errors.zipCode}
                sx={{ gridColumn: "span 1" }}
              />
              <Box sx={{ gridColumn: "span 1" }}>
                <FormLabel id="moving-label">Customer moving ?</FormLabel>
                <RadioGroup
                  row
                  id="moving"
                  aria-labelledby="moving-label"
                  name="moving"
                  label="moving"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.moving}
                  // defaultChecked={false}
                  error={!!touched.moving && !!errors.moving}
                  helperText={touched.moving && errors.moving}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio color="secondary" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio color="secondary" />}
                    label="No"
                  />
                </RadioGroup>
              </Box>
              <Box sx={{ gridColumn: "span 1" }}>
                <FormLabel id="present-label">Customer present ?</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="present-label"
                  name="present"
                  label="present"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.present}
                  error={!!touched.present && !!errors.present}
                  helperText={touched.present && errors.present}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio color="secondary" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio color="secondary" />}
                    label="No"
                  />
                </RadioGroup>
              </Box>
            </Box>
            {values.moving === 1 ? (
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              >
                {" "}
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Previous Address 1"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.prevaddress1}
                  name="prevaddress1"
                  error={!!touched.prevaddress1 && !!errors.prevaddress1}
                  helperText={touched.prevaddress1 && errors.prevaddress1}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Previous Apt / Suite / Lot"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.prevApt}
                  name="prevApt"
                  error={!!touched.prevApt && !!errors.prevApt}
                  helperText={touched.prevApt && errors.prevApt}
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Previous Zip code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.prevZip}
                  name="prevZip"
                  error={!!touched.prevZip && !!errors.prevZip}
                  helperText={touched.prevZip && errors.prevZip}
                  sx={{ gridColumn: "span 1" }}
                />
              </Box>
            ) : (
              ""
            )}
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap="10px"
              mt="10px"
            >
              {/* OTP Entry Field */}
              {otpSent && !isOtpValid && (
                <TextField
                  variant="filled"
                  label="Enter OTP"
                  value={enteredOtp}
                  onChange={(e) => setEnteredOtp(e.target.value)}
                  sx={{ width: "200px", mb: "10px" }}
                />
              )}

              {/* Place Order Button */}
              <Button
                type="button"
                color="secondary"
                variant="contained"
                onClick={() => {
                  if (needsOtp()) {
                    if (!otpSent) {
                      // Step 1: Send OTP
                      const generatedOtp = Math.floor(
                        100000 + Math.random() * 900000
                      ).toString();
                      setOtp(generatedOtp);

                      fetch(
                        "https://us-central1-argon-fx-383518.cloudfunctions.net/sendOtp",
                        {
                          method: "POST",
                          headers: { "Content-Type": "application/json" },
                          body: JSON.stringify({
                            phoneNumber: values.mobile,
                            otp: generatedOtp,
                          }),
                        }
                      )
                        .then((response) => {
                          if (response.ok) {
                            notify("OTP sent successfully!");
                            setLockedNumber(values.mobile);
                            setOtpSent(true);
                          } else {
                            notifyError(
                              "Failed to send OTP. Please try again."
                            );
                          }
                        })
                        .catch((error) => {
                          console.error("Error sending OTP:", error);
                          notifyError("Error: Unable to send OTP.");
                        });
                    } else if (!isOtpValid) {
                      // Step 2: Verify OTP
                      if (enteredOtp === otp) {
                        setIsOtpValid(true);
                        notify("OTP verified successfully!");

                        // Auto-submit after OTP verification if required
                        handleSubmit(values);
                      } else {
                        notifyError("Invalid OTP. Please try again.");
                      }
                    }
                  } else {
                    // If OTP is not required, just place the order directly
                    handleSubmit(values);
                  }
                }}
                disabled={loading}
                sx={{ width: "200px" }}
              >
                {loading
                  ? "Processing..."
                  : needsOtp()
                  ? !otpSent
                    ? "Send OTP"
                    : !isOtpValid
                    ? "Verify OTP"
                    : "Place Order"
                  : "Place Order"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </div>
  );
};

// const phoneRegExp =
//   /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
const phoneRegExp =
  /^(?:(?:\+?[1-9]{1,4}[ -]?)|(?:\([0-9]{2,3}\)[ -]?)|(?:[0-9]{2,4}[ -]?))*[0-9]{10}$/;

const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup.string().email("invalid email"),
  mobile: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .test("len", "Must be exactly 10 characters", (val) => {
      if (val) return val.toString().length === 10;
    })
    .required("required"),
  address1: yup.string().required("required"),
  zipCode: yup
    .string()
    .test("len", "Must be exactly 5 characters", (val) => {
      if (val) return val.toString().length === 5;
    })
    .required("required"),
  moving: yup.string().required("A radio option is required"),

  // Conditional validation for adType based on whether the section is shown
  adType: yup.string().when("$showAdTypeSection", {
    is: true, // when showAdTypeSection is true (section is displayed)
    then: yup.string().required("You must select an Ad Type"),
    otherwise: yup.string().notRequired(),
  }),

  // Conditional validation for campaign based on whether adType is selected
  campaign: yup.string().when("adType", {
    is: (adType) => adType && adType !== "", // if adType is selected
    then: yup.string().required("You must select a campaign"),
    otherwise: yup.string().notRequired(),
  }),
});

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  address1: "",
  address2: "",
  prevaddress1: "",
  prevApt: "",
  prevZip: "",
  zipCode: "",
  moving: false,
  present: true,
  adType: "",
  campaign: "",
};

export default Iframe;
