import { Box, Button, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { Link, useLocation } from "react-router-dom";

const Profile = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const PartnerDetails = props.PartnerDetails || {};
  const PartnerObject = props.PartnerObject || {};
  const location = useLocation(); 

  return (
    <Box
      m="20px"
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap="15px"
    >
      {/* Owner Training Status */}
      <Box gridColumn="span 12" textAlign="center" mb="0px" >
        <Typography variant="h5" fontWeight="600" mt={'-20px'}>
          Training Status
        </Typography>
      </Box>

        {/* single training */}
        <Box gridColumn="span 12" textAlign="center" mb="10px" display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap="15px">
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[100]}
          p="15px"
          textAlign="center"
          boxShadow={3}
        >
          <Typography variant="h5" fontWeight="600" color={colors.primary[500]}>
            Mobile
          </Typography>
          <Typography variant="body1" color={colors.primary[900]}>
          {PartnerDetails.MobileTraining}
          </Typography>
        </Box>

                {/* single training */}
                <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[100]}
          p="15px"
          textAlign="center"
          boxShadow={3}
        >
          <Typography variant="h5" fontWeight="600" color={colors.primary[500]}>
            Internet
          </Typography>
          <Typography variant="body1" color={colors.primary[900]}>
          {PartnerDetails.IntSpec1}
          </Typography>
        </Box>

                {/* single training */}
                <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[100]}
          p="15px"
          textAlign="center"
          boxShadow={3}
        >
          <Typography variant="h5" fontWeight="600" color={colors.primary[500]}>
            Portal
          </Typography>
          <Typography variant="body1" color={colors.primary[900]}>
          {PartnerDetails.PortalTraining}
          </Typography>
        </Box>

                {/* single training */}
                <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[100]}
          p="15px"
          textAlign="center"
          boxShadow={3}
        >
          <Typography variant="h5" fontWeight="600" color={colors.primary[500]}>
            Marketing
          </Typography>
          <Typography variant="body1" color={colors.primary[900]}>
          {PartnerDetails.MarketingTraining}
          </Typography>
        </Box>

                {/* single training */}
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[100]}
          p="15px"
          textAlign="center"
          boxShadow={3}
        >
          <Typography variant="h5" fontWeight="600" color={colors.primary[500]}>
            Sales 101
          </Typography>
          <Typography variant="body1" color={colors.primary[900]}>
          {PartnerDetails.Sales101}
          </Typography>
        </Box>

                        {/* single training */}
                        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[100]}
          p="15px"
          textAlign="center"
          boxShadow={3}
        >
          <Typography variant="h5" fontWeight="600" color={colors.primary[500]}>
            Call flow
          </Typography>
          <Typography variant="body1" color={colors.primary[900]}>
          {PartnerDetails.MobileTraining}
          </Typography>
        </Box>
        </Box>

                        {/* single training
                        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[100]}
          p="15px"
          textAlign="center"
          boxShadow={3}
        >
          <Typography variant="h5" fontWeight="600" color={colors.primary[500]}>
            Role Play
          </Typography>
          <Typography variant="body1" color={colors.primary[500]}>
          {PartnerDetails.MobileTraining}
          </Typography>
        </Box> */}


      {/* Partner Information */}
      <Box
        gridColumn="span 4"
        backgroundColor={colors.primary[100]}
        p="15px"
        boxShadow={3}
      >
        <Typography variant="h5" fontWeight="600" color={colors.primary[500]}>
          Partner Information
        </Typography>
        <Typography variant="body1" color={colors.primary[900]}>
          {PartnerDetails.FirstName || "N/A"} {PartnerDetails.LastName || "N/A"}
        </Typography>
        <Typography variant="body1" color={colors.primary[900]}>
          {PartnerDetails.Mobile || "N/A"}
        </Typography>
        <Typography variant="body1" color={colors.primary[900]}>
          {PartnerDetails.EmailDisplay || "N/A"}
        </Typography>
      </Box>

      {/* Company Information */}
      <Box
        gridColumn="span 4"
        backgroundColor={colors.primary[100]}
        p="15px"
        boxShadow={3}
      >
        <Typography variant="h5" fontWeight="600" color={colors.primary[500]}>
          Company Information
        </Typography>
        <Typography variant="body1" color={colors.primary[900]}>
          {PartnerDetails.Address || "N/A"}
        </Typography>
        <Typography variant="body1" color={colors.primary[900]}>
          {PartnerDetails.City || "N/A"}, {PartnerDetails.State || "N/A"}{" "}
          {PartnerDetails.Zip || "N/A"}
        </Typography>
        <Typography variant="body1" color={colors.primary[900]}>
          {PartnerDetails.Store || "N/A"}
        </Typography>
      </Box>

      {/* Call Center Information */}
      <Box
        gridColumn="span 4"
        backgroundColor={colors.primary[100]}
        p="15px"
        boxShadow={3}
      >
        <Typography variant="h5" fontWeight="600" color={colors.primary[500]}>
          Call Center Information
        </Typography>
        <Typography variant="body1" color={colors.primary[900]}>
          405 E Holt Blvd
        </Typography>
        <Typography variant="body1" color={colors.primary[90]}>
          Ontario CA 91762
        </Typography>
        <Typography variant="body1" color={colors.primary[900]}>
          909-983-1001
        </Typography>
      </Box>


      {/* Redesigned Bottom Section */}
      <Box
        gridColumn="span 12"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gap="15px"
      >
        {/* Hover-Expanding Large Rectangles */}
        <Box
          alignContent="center"
          height="100%"
          justifyItems="center"
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[100]}
          p="15px"
          textAlign="center"
          boxShadow={3}
          sx={{
            cursor: "pointer",
            "&:hover": {
              transform: "scale(1.35)",
              transition: "transform 0.3s ease",
            },
          }}
        >
          <Typography variant="body1" color={colors.primary[500]}>
            Copy of Owner ID
          </Typography>
          <img
            src={
              process.env.PUBLIC_URL +
              `assets/logo.webp`
            }
            alt={`ssssssssssssLogo`}
          />
        </Box>
        <Box
          alignContent="center"
          height="100%"
          justifyItems="center"
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[100]}
          p="15px"
          textAlign="center"
          boxShadow={3}
          sx={{
            cursor: "pointer",
            "&:hover": {
              transform: "scale(1.35)",
              transition: "transform 0.3s ease",
            },
          }}
        >
          <Typography variant="body1" color={colors.primary[500]}>
            Reseller Permit/Business License
          </Typography>
          <img
            src={
              process.env.PUBLIC_URL +
              `assets/logo.webp`
            }
            alt={`ssssssssssssLogo`}
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[100]}
          p="15px"
          textAlign="center"
          boxShadow={3}
          sx={{
            cursor: "pointer",
            "&:hover": {
              transform: "scale(1.35)",
              transition: "transform 0.3s ease",
            },
          }}
        >
          <Typography variant="body1" color={colors.primary[500]}>
            Partner App
          </Typography>
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[100]}
          p="15px"
          textAlign="center"
          boxShadow={3}
          sx={{
            cursor: "pointer",
            "&:hover": {
              transform: "scale(1.35)",
              transition: "transform 0.3s ease",
            },
          }}
        >
          <Typography variant="body1" color={colors.primary[500]}>
            Partner Int
          </Typography>
        </Box>
        {/* Smaller Image Tiles */}
        {[...Array(8)].map((_, index) => (
          <Box
            key={index}
            gridColumn="span 3"
            backgroundColor={colors.primary[100]}
            p="15px"
            textAlign="center"
            boxShadow={3}
            sx={{
              cursor: "pointer",
              "&:hover": {
                transform: "scale(1.35)",
                transition: "transform 0.3s ease",
              },
            }}
          >
            <Typography variant="body1" color={colors.primary[500]}>
              {index % 2 === 0 ? "Exterior" : "Interior"} {Math.floor(index / 2) + 1}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Profile;


// import { Box, Button, Typography, useTheme } from "@mui/material";
// import { DataGrid } from "@mui/x-data-grid";
// import { tokens } from "../../theme";
// import Header from "../../components/Header";
// import { useEffect, useState } from "react";
// import { Link, useLocation } from "react-router-dom";

// const Profile = (props) => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const [data, setData] = useState([]);
//   // const [PartnerDetails, setPartnerDetails] = useState({});

//   let Agent = props.agent;
//   // const PartnerObject = props.PartnerObject;

//   const location = useLocation();
//   const PartnerObject = props.PartnerObject;
//   const PartnerDetails = props.PartnerDetails;

//   useEffect(() => {
//     if (Agent && Agent.PartnerID && data.length == 0) {
//       //3344556677
//       console.log("if statement", Agent.PartnerID);
//       // Start call chain
//       console.log("Partner Object PROP: ", PartnerObject);
//       console.log("PartnerDetails: ", PartnerDetails.FirstName);
//     }
//   }, [Agent, PartnerObject]);

//   return (
//     <Box
//       m="10px 0 40px 0"
//       display="grid"
//       gridTemplateColumns="repeat(12, 1fr)"
//       rowGap="15px"
//       columnGap="15px"
//       justifyContent={"center"}
//     >
//       {/* Primary owner information */}
//       <Box
//         sx={{ gridColumn: "span 4", boxShadow: 3 }}
//         backgroundColor="#f2f0f0"
//         padding="15px"
//       >
//         <Typography color={colors.primary[500]} variant="h5" fontWeight="600">
//           Primary Owner Information
//         </Typography>
//         <Typography color={colors.primary[500]} variant="h5" fontWeight="600">
//           {PartnerDetails.FirstName + " " + PartnerDetails.LastName}
//         </Typography>
//         <Typography color={colors.primary[500]} variant="h5" fontWeight="600">
//           {PartnerDetails.EmailDisplay}
//         </Typography>
//         <Typography color={colors.primary[500]} variant="h5" fontWeight="600">
//           {PartnerDetails.Mobile}
//         </Typography>
//       </Box>
//       <Box
//         m="0px 0 0px 0"
//         display="grid"
//         gridTemplateColumns="repeat(12, 1fr)"
//         columnGap="15px"
//         padding="15px"
//         sx={{ gridColumn: "span 8", boxShadow: 3 }}
//         backgroundColor="#f2f0f0"
//         justifyContent={"center"}
//       >
//         <Box sx={{ gridColumn: "span 12" }}>
//           <Typography color={colors.primary[500]} variant="h5" fontWeight="600">
//             Commission Tiers
//           </Typography>
//         </Box>
//         <Box sx={{ gridColumn: "span 4" }} backgroundColor="#f2f0f0">
//           <Typography color={colors.primary[500]} variant="h5" fontWeight="600">
//             Volume Tier:
//           </Typography>
//           <Typography color={colors.grey[100]} variant="h5">
//             {PartnerDetails.CommissionTier}
//           </Typography>
//         </Box>
//         <Box sx={{ gridColumn: "span 4" }} backgroundColor="#f2f0f0">
//           <Typography color={colors.primary[500]} variant="h5" fontWeight="600">
//             Chargeback Tier:
//           </Typography>
//           <Typography color={colors.primary[500]} variant="h5">
//             {PartnerDetails.CBTier}
//           </Typography>
//         </Box>
//         <Box sx={{ gridColumn: "span 4" }} backgroundColor="#f2f0f0">
//           <Typography color={colors.primary[500]} variant="h5" fontWeight="600">
//             Mobile Tier:
//           </Typography>
//           <Typography color={colors.primary[500]} variant="h5">
//             {PartnerDetails.MobileTier}
//           </Typography>
//         </Box>
//       </Box>
//       <Box
//         m="0px 0 40px 0"
//         display="grid"
//         gridTemplateColumns="repeat(12, 1fr)"
//         rowGap="15px"
//         columnGap="15px"
//         padding="15px"
//         sx={{ gridColumn: "span 4", boxShadow: 3 }}
//         backgroundColor="#f2f0f0"
//         justifyContent={"center"}
//       >
//         <Box sx={{ gridColumn: "span 12" }} backgroundColor="#f2f0f0">
//           <Typography color={colors.primary[500]} variant="h5" fontWeight="600">
//             Company Information
//           </Typography>
//           <Typography color={colors.primary[500]} variant="h5">
//             {PartnerDetails.DisplayName}
//           </Typography>
//           <Typography color={colors.primary[500]} variant="h5">
//             {PartnerDetails.Address}
//           </Typography>
//           <Typography color={colors.primary[500]} variant="h5">
//             {PartnerDetails.AddressLine2}
//           </Typography>
//           <Typography color={colors.primary[500]} variant="h5">
//             {PartnerDetails.City} {PartnerDetails.State} {PartnerDetails.Zip}
//           </Typography>
//           <Typography color={colors.primary[500]} variant="h5">
//             P: {PartnerDetails.Store}
//           </Typography>
//           <Typography color={colors.primary[500]} variant="h5">
//             E: {PartnerDetails.EmailDisplay}
//           </Typography>
//         </Box>
//       </Box>
//       <Box
//         m="0px 0 40px 0"
//         display="grid"
//         gridTemplateColumns="repeat(1, 1fr)"
//         rowGap="15px"
//         columnGap="15px"
//         padding="15px"
//         sx={{ gridColumn: "span 4", boxShadow: 3 }}
//         backgroundColor="#f2f0f0"
//         justifyContent={"center"}
//       >
//         <Button variant="contained" color="secondary">
//           Partner Application
//         </Button>
//         <Button variant="contained" color="secondary">
//           Partner Interview
//         </Button>
//         <Button variant="contained" color="secondary">
//           Reseller Permit/Drivers License
//         </Button>
//         <Button variant="contained" color="secondary">
//           Drivers License
//         </Button>
//         <Button variant="contained" color="secondary">
//           Main office Pictures
//         </Button>
//         <Button variant="contained" color="secondary">
//           Call Center Pictures
//         </Button>
//       </Box>
//       {/* <Box
//         m="0px 0 40px 0"
//         display="grid"
//         gridTemplateColumns="repeat(1, 1fr)"
//         rowGap="15px"
//         columnGap="15px"
//         padding="15px"
//         sx={{ gridColumn: "span 4", boxShadow: 3 }}
//         backgroundColor="#f2f0f0"
//         justifyContent={"center"}
//         maxHeight={"300px"}
//         overflow={"scroll"}
//       >
//         <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
//           Agent List
//         </Typography>
//         {PartnerObject.Agents.map((agent, index) => (
//           <Typography
//             key={index} // Make sure to add a unique key for each element in the list
//             color={colors.grey[100]}
//             variant="h6"
//           >
//             {agent}
//           </Typography>
//         ))}
//       </Box> */}
//     </Box>
//   );
// };

// export default Profile;
