import { useEffect, useState } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import { Link } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Header from "../components/Header";

const Team = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [partnerArray, setPartnerArray] = useState([]);
  const [period, setPeriod] = useState("all");

  // Fetch Partner Details for Marketing
  useEffect(() => {
    fetchDataFromDatabase();
  }, [period]);

  const fetchDataFromDatabase = async () => {
    let url =
      "https://us-central1-argon-fx-383518.cloudfunctions.net/SpectrumQuickView";

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ period }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (!Array.isArray(data)) {
        console.error("Expected data to be an array, received:", data);
        // notifyError("Data fetch failed: Expected array response");
        return;
      }

      // Ensure each row has unique ID
      const formattedData = data
        .filter((item) => item.PartnerID && item.PartnerName) // Filtering to ensure valid data
        .map((item, index) => ({
          ...item,
          id: item.PartnerID || index,
        }));

      console.log("Formatted Data:", formattedData);
      setPartnerArray(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
      // notifyError("Error fetching data: " + error.message);
    }
  };

  const handleChange = (event) => {
    let selectedPeriod = event.target.value;
    setPeriod(selectedPeriod);
  };

  const columns = [
    {
      field: "PartnerName",
      headerName: "Partner Name",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row }) => {
        const { PartnerName, PartnerID } = row;
        console.log('row: ', row)
        return (
          <Link
            to="/spectrumPartnerPortfolio"
            state={{ PartnerID: PartnerID, PartnerObject: row }} // Passing the full row data x
          >
            {PartnerName}
          </Link>
        );
      },
    },
    {
      field: "SalesTrend",
      headerName: "SalesTrend",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { SalesTrend } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              SalesTrend > 9
                ? "#5dc579"
                : SalesTrend < -81
                ? "#b3605e"
                : "#e3b81b"
            }
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "ChargebackPercentage",
      headerName: "Chargeback %",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { ChargebackPercentage } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              ChargebackPercentage > 24
                ? "#b3605e"
                : ChargebackPercentage < 16
                ? "#5dc579"
                : "#e3b81b"
            }
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "CanceledPercentage",
      headerName: "Canceled %",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { CanceledPercentage } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              CanceledPercentage > 14.99
                ? "#b3605e"
                : CanceledPercentage < 7
                ? "#5dc579"
                : "#e3b81b"
            }
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "GigPercentage",
      headerName: "Gig %",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { GigPercentage } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              GigPercentage > 17.99
                ? "#b3605e"
                : GigPercentage < 8
                ? "#5dc579"
                : "#e3b81b"
            }
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "VideoPercentage",
      headerName: "Video %",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { VideoPercentage } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={VideoPercentage > 0 ? "#b3605e" : "#5dc579"}
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "MarketingCompliance",
      headerName: "Marketing Compliance",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { MarketingCompliance } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              MarketingCompliance == "Compliant" ? "#5dc579" : "#b3605e"
            }
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "CallCalibrations",
      headerName: "Call Calibration",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { CallCalibrations } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              CallCalibrations == "Compliant" ? "#5dc579" : "#b3605e"
            }
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "Training",
      headerName: "Training",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { Training } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={Training == "Yes" ? "#5dc579" : "#b3605e"}
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "Status",
      headerName: "Status",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { Status } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              Status == "Active"
                ? "#5dc579"
                : Status == "Disabled"
                ? "#b3605e"
                : "#e3b81b"
            }
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
  ];

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Spectrum Quick View" subtitle="Partner Overview" />

        {/* <Box>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Period</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={period}
              label="Period"
              onChange={handleChange}
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"november"}>November</MenuItem>
              <MenuItem value={"december"}>December</MenuItem>
              <MenuItem value={"january"}>January</MenuItem>
            </Select>
          </FormControl>
        </Box> */}
      </Box>
      <Box
        m="10px 0 40px 0"
        height="75vh"
        sx={{
          "& .MuiTablePagination-root": {
            color: colors.lightHeaders[100],
          },
          "& .MuiDataGrid-root": {
            border: "none",
            color: colors.lightHeaders[100],
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.lightHeaders[100],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
            color: colors.lightHeaders[100],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={Array.isArray(partnerArray) ? partnerArray : []}
          columns={columns}
          getRowId={(row) => row.id}
        />
      </Box>
    </Box>
  );
};

export default Team;
