import {
  Box,
  IconButton,
  useTheme,
  Modal,
  Typography,
  Button,
} from "@mui/material";
import { useState, useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { Link } from "react-router-dom";

const Topbar = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const Agent = props.agent;
  console.log("agt: ", Agent);

  // State for clock-in/clock-out
  const [modalOpen, setModalOpen] = useState(false);
  const [isClockedIn, setIsClockedIn] = useState(false);
  const [clockInTime, setClockInTime] = useState(null);

  // Handle modal open/close
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  // Clock-in handler
  const handleClockIn = () => {
    const now = new Date();
    setClockInTime(now);
    setIsClockedIn(true);
    console.log("Clocked in at:", now);
    handleClose(); // Close modal
    writeClockIn();
    // TODO: Send clock-in event to the database
  };

  const writeClockIn = async () => {
    let payload = {
      AgentID: Agent.AgentID,
      PartnerID: Agent.PartnerID,
      Name: Agent.FirstName + " " + Agent.LastName,
    };
    console.log(payload);

    try {
      const response = await fetch(
        "https://us-central1-argon-fx-383518.cloudfunctions.net/writeClockin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      // Check if the response is okay
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Parse the response as JSON
      const data = await response.json();

      // Use the message from the parsed data
      // notify(data.message);
      console.log("Response: ", data);
    } catch (error) {
      // Handle error
      // notifyError("Error: " + error.message + " [LE004]");
      console.log("Error: " + error.message + " [LE004]");
    }
  };

  // Clock-out handler
  const handleClockOut = () => {
    const now = new Date();
    setIsClockedIn(false);
    console.log("Clocked out at:", now);
    handleClose(); // Close modal
    writeClockIn();
    // TODO: Send clock-out event to the database, including clockInTime and elapsed time
  };

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
        color={colors.lightHeaders[100]}
      >
        <InputBase
          sx={{ ml: 2, flex: 1, color: colors.lightHeaders[100] }}
          placeholder="Search"
        />
        <IconButton
          type="button"
          sx={{ p: 1, color: colors.lightHeaders[100] }}
        >
          <SearchIcon />
        </IconButton>
      </Box>

      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={handleOpen}>
          <AccessAlarmIcon sx={{ color: isClockedIn ? "green" : "red" }} />
        </IconButton>
        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton component={Link} to="/account">
          <PersonOutlinedIcon />
        </IconButton>
      </Box>

      {/* Clock-In/Clock-Out Modal */}
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="clock-modal-title"
        aria-describedby="clock-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          {isClockedIn ? (
            <>
              <Typography id="clock-modal-title" variant="h6" component="h2">
                Clock Out
              </Typography>
              <Typography id="clock-modal-description" sx={{ mt: 2 }}>
                Elapsed Time
              </Typography>
              <Box
                sx={{
                  mt: 2,
                  mb: 2,
                  p: 2,
                  border: "2px solid black",
                  display: "inline-block",
                  fontSize: "2rem",
                  fontWeight: "bold",
                }}
              >
                {/* Calculate elapsed time */}
                {(() => {
                  const now = new Date();
                  const diff = now - clockInTime;
                  const minutes = Math.floor(diff / (1000 * 60));
                  const seconds = Math.floor((diff / 1000) % 60);
                  return `${String(minutes).padStart(2, "0")}:${String(
                    seconds
                  ).padStart(2, "0")}`;
                })()}
              </Box>
              <Typography>
                Clocked In at:{" "}
                {clockInTime ? clockInTime.toLocaleTimeString() : "--:--"}
              </Typography>
              <Box display="flex" justifyContent="space-between" mt={3}>
                <Button onClick={handleClose} variant="outlined" sx={{ mr: 1 }}>
                  Close
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClockOut}
                >
                  Clock Out
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Typography id="clock-modal-title" variant="h6" component="h2">
                Clock In
              </Typography>
              <Typography id="clock-modal-description" sx={{ mt: 2 }}>
                Are you ready to clock in?
              </Typography>
              <Box display="flex" justifyContent="space-between" mt={3}>
                <Button onClick={handleClose} variant="outlined" sx={{ mr: 1 }}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClockIn}
                >
                  Clock In
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default Topbar;

// import {
//   Box,
//   IconButton,
//   useTheme,
//   Modal,
//   Typography,
//   Button,
// } from "@mui/material";
// import { useState, useContext } from "react";
// import { ColorModeContext, tokens } from "../../theme";
// import InputBase from "@mui/material/InputBase";
// import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
// import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
// import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
// import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
// import SearchIcon from "@mui/icons-material/Search";
// import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
// import { Link } from "react-router-dom";
// import ClockOutModal from "./ClockOutModal";

// const Topbar = () => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const colorMode = useContext(ColorModeContext);

//   // State for clock-in and clock-out modals
//   const [clockInOpen, setClockInOpen] = useState(false);
//   const [clockOutOpen, setClockOutOpen] = useState(false);
//   const [clockInTime, setClockInTime] = useState(null);

//   // Handlers for clock-in modal
//   const handleClockInOpen = () => setClockInOpen(true);
//   const handleClockInClose = () => setClockInOpen(false);

//   // Clock-in action handler
//   const handleClockIn = () => {
//     setClockInTime(new Date());
//     console.log("Clocked in!");
//     handleClockInClose(); // Close the modal after clocking in
//     setClockOutOpen(true); // Open clock-out modal
//   };

//   // Handlers for clock-out modal
//   const handleClockOutClose = () => setClockOutOpen(false);

//   return (
//     <Box display="flex" justifyContent="space-between" p={2}>
//       {/* SEARCH BAR */}
//       <Box
//         display="flex"
//         backgroundColor={colors.primary[400]}
//         borderRadius="3px"
//         color={colors.lightHeaders[100]}
//       >
//         <InputBase
//           sx={{ ml: 2, flex: 1, color: colors.lightHeaders[100] }}
//           placeholder="Search"
//         />
//         <IconButton
//           type="button"
//           sx={{ p: 1, color: colors.lightHeaders[100] }}
//         >
//           <SearchIcon />
//         </IconButton>
//       </Box>

//       {/* ICONS */}
//       <Box display="flex">
//         <IconButton onClick={handleClockInOpen}>
//           <AccessAlarmIcon />
//         </IconButton>
//         <IconButton>
//           <NotificationsOutlinedIcon />
//         </IconButton>
//         <IconButton>
//           <SettingsOutlinedIcon />
//         </IconButton>
//         <IconButton component={Link} to="/account">
//           <PersonOutlinedIcon />
//         </IconButton>
//       </Box>

//       {/* Clock-In Modal */}
//       <Modal
//         open={clockInOpen}
//         onClose={handleClockInClose}
//         aria-labelledby="clock-in-modal-title"
//         aria-describedby="clock-in-modal-description"
//       >
//         <Box
//           sx={{
//             position: "absolute",
//             top: "20%",
//             right: "2%",
//             transform: "translate(-50%, -50%)",
//             width: 300,
//             bgcolor: "background.paper",
//             borderRadius: "10px",
//             boxShadow: 24,
//             p: 4,
//           }}
//         >
//           <Typography id="clock-in-modal-title" variant="h6" component="h2">
//             Clock In:
//           </Typography>
//           <Typography id="clock-in-modal-description" sx={{ mt: 2 }}>
//             Are you ready to clock in?
//           </Typography>
//           <Box display="flex" justifyContent="flex-end" mt={2}>
//             <Button
//               variant="contained"
//               onClick={handleClockInClose}
//               sx={{ mr: 1 }}
//             >
//               Cancel
//             </Button>
//             <Button variant="contained" onClick={handleClockIn}>
//               Clock In
//             </Button>
//           </Box>
//         </Box>
//       </Modal>

//       {/* Clock-Out Modal */}
//       <ClockOutModal
//         open={clockOutOpen}
//         handleClose={handleClockOutClose}
//         clockInTime={clockInTime}
//       />
//     </Box>
//   );
// };

// export default Topbar;
