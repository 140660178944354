import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../theme";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";

const Training = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Agent = props.agent;
  const [joseConnect, setJoseConnect] = useState(false);

  let JoseConnect = false;

  useEffect(() => {
    if (Agent && Agent.PartnerID) {
      console.log("agent: ", Agent);
      if (["VTL1000", "VTL1001", "VTL1002"].includes(Agent.PartnerID)) {
        setJoseConnect(true);
      }
    }
  }, [Agent]);

  return (
    <Box
      className="m-5"
      display="grid"
      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
    >
      <Header
        title="Training"
        subtitle="Vomtel training material"
        sx={{ gridColumn: "span 4" }}
      />
      <Box
        sx={{ gridColumn: "span 4" }}
        className="mb-4"
        display="grid"
        gridTemplateColumns="repeat(7, minmax(0, auto))"
        columnGap={"20px"}
        rowGap={"20px"}
        justifyContent={"start"}
      >
        <Box sx={{ gridColumn: "span 7" }} className="mb-1">
          <Typography color={colors.greenAccent[500]} variant="h3">
            Required training
          </Typography>
        </Box>
        <Box sx={{ gridColumn: "span 1" }}>
          <a href={"/MarketingDnD"} target="">
            <img
              src={process.env.PUBLIC_URL + "/assets/Spectrum Marketing.webp"}
              alt=""
            />
          </a>
        </Box>
        <Box sx={{ gridColumn: "span 1" }}>
          <a href={"/InternetSpectrumOne"} target="" class="j7pOnl">
            <img
              src={
                process.env.PUBLIC_URL + "/assets/internetandspectrumone.png"
              }
              alt=""
            />
          </a>
        </Box>
        <Box sx={{ gridColumn: "span 1" }}>
          <a href={"/SpectrumMobile"} target="" class="j7pOnl">
            <img
              src={process.env.PUBLIC_URL + "assets/Spectrum Mobile.webp"}
              alt=""
            />
          </a>
        </Box>
        <Box sx={{ gridColumn: "span 1" }}>
          <a // PORTTAL TRAINING
            href={"/PortalTraining"}
            target=""
            class="j7pOnl"
          >
            <img
              src={process.env.PUBLIC_URL + "assets/Portal Training.png"}
              alt=""
            />
          </a>
        </Box>

        <Box sx={{ gridColumn: "span 1" }}>
          <a href={"/Sales101"} target="" class="j7pOnl">
            <img src={process.env.PUBLIC_URL + "assets/Sales 101.png"} alt="" />
          </a>
        </Box>
        <Box sx={{ gridColumn: "span 1" }}>
          <a href={"/CallFlow"} target="" class="j7pOnl">
            <img src={process.env.PUBLIC_URL + "assets/Call Flow.png"} alt="" />
          </a>
        </Box>

        {joseConnect ? (
          <Box sx={{ gridColumn: "span 1" }}>
            <a
              href="https://calendly.com/j-castro-24/letsconnect?back=1&month=2024-11"
              target="_blank"
              class="j7pOnl"
            >
              <img src={process.env.PUBLIC_URL + "assets/Jose.png"} alt="" />
            </a>
          </Box>
        ) : (
          ""
        )}
      </Box>
      <Box
        sx={{ gridColumn: "span 4" }}
        className="mb-4"
        display="grid"
        gridTemplateColumns="repeat(5, minmax(0, auto))"
        columnGap={"20px"}
        justifyContent={"start"}
      >
        <Box sx={{ gridColumn: "span 5" }} className="mt-4 mb-1">
          <Typography color={colors.greenAccent[500]} variant="h3">
            Spectrum required training
          </Typography>
        </Box>
        <Box sx={{ gridColumn: "span 1" }}>
          <a
            href={
              "https://360.articulate.com/review/content/6e576eeb-2dcb-4446-beb6-20898543af31/review"
            }
            target="_blank"
            class="j7pOnl"
          >
            <img
              src={process.env.PUBLIC_URL + "assets/Retail Order PArt 1.webp"}
              alt=""
            />
          </a>
          Password: Retail
        </Box>
        <Box sx={{ gridColumn: "span 1" }}>
          <a
            href={
              "https://360.articulate.com/review/content/9f60bdb9-8c0b-4a5b-b4f9-73f1bc3be6bc/review"
            }
            target="_blank"
            class="j7pOnl"
          >
            <img
              src={process.env.PUBLIC_URL + "assets/Retail Order PArt 2.webp"}
              alt=""
            />
          </a>
          Password: Retail
        </Box>
        <Box sx={{ gridColumn: "span 1" }}>
          <a
            href={"https://core-charter.bravais.com/s/qyiWKghkHhtOQNQ3oaoO"}
            target="_blank"
            class="j7pOnl"
          >
            <img
              src={process.env.PUBLIC_URL + "assets/Spectrum Mobile.webp"}
              alt=""
            />
          </a>
        </Box>
      </Box>
    </Box>
  );
};

export default Training;
