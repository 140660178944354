// import React from "react";
// import { useLocation } from "react-router-dom";
// import { Box, Button, useTheme } from "@mui/material";
// import { Link } from "react-router-dom";
// import { tokens } from "../../theme";
// import Header from "../../components/Header";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";

// const ShowPost = (props) => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const location = useLocation();
//   const { title, body, id } = location.state;
//   const navigate = useNavigate();
//   console.log(location.state);
//   const Agent = props.agent;
//   const notify = (x) => toast(x);
//   const notifyError = (x) => toast.error(x);

//   const handleFormSubmit = async (event) => {
//     event.preventDefault();
//     let formData = { id: id };

//     try {
//       const response = await fetch(
//         "https://us-central1-argon-fx-383518.cloudfunctions.net/deletePost", // Replace with your Cloud Function URL
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(formData),
//         }
//       );
//       console.log("inner: ", formData);
//       console.log("inner strngfy: ", JSON.stringify(formData));

//       if (response.ok) {
//         // Form submitted successfully
//         notify("Post deleted successfully!");
//         console.log("Form deleted successfully!");
//         navigate("/news");
//       }
//     } catch (error) {
//       console.error(error);
//       notifyError("Error: " + error.message + " [SE-NEWS]");
//     }
//   };

//   return (
//     <Box
//       className="m-5"
//       display="grid"
//       gridTemplateColumns="repeat(4, minmax(0, 1fr))"
//     >
//       <Header
//         title={title}
//         subtitle="posted by Vomtel"
//         sx={{ gridColumn: "span 4" }}
//       />
//       <Box
//         dangerouslySetInnerHTML={{ __html: body }}
//         sx={{
//           gridColumn: "span 4",
//           "& h1": { fontSize: "26px", fontWeight: "bold" },
//           "& h2": { fontSize: "22px", fontWeight: "bold" },
//           "& h3": { fontSize: "18px", fontWeight: "bold" },
//           "& p": { fontSize: "14px", lineHeight: "1.5" },
//           "& a": { textDecoration: "underline" },
//         }}
//       ></Box>
//       <Box
//         sx={{ gridColumn: "span 4" }}
//         display="flex"
//         justifyContent="space-between"
//         alignItems="center"
//         className="mb-0"
//       >
//         <Box></Box>
//         {Agent !== undefined && Agent.Hierarchy == "Master" && (
//           <Box>
//             <Button
//               sx={{
//                 backgroundColor: colors.blueAccent[700],
//                 color: colors.grey[100],
//                 fontSize: "14px",
//                 fontWeight: "bold",
//                 padding: "10px 20px",
//                 marginRight: "10px",
//               }}
//               component={Link}
//               to="/editPost"
//               state={{ title: title, body: body, id: id }}
//             >
//               <EditIcon sx={{ mr: "10px" }} />
//               edit
//             </Button>
//             <Button
//               sx={{
//                 backgroundColor: colors.blueAccent[700],
//                 color: colors.grey[100],
//                 fontSize: "14px",
//                 fontWeight: "bold",
//                 padding: "10px 20px",
//               }}
//               onClick={handleFormSubmit}
//             >
//               <DeleteIcon sx={{ mr: "10px" }} />
//               Delete
//             </Button>
//           </Box>
//         )}
//       </Box>
//     </Box>
//   );
// };

// export default ShowPost;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Button, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Quill from "quill";

const ShowPost = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const { title = "No Title", body = "", id } = location.state || {};
  const navigate = useNavigate();
  const [htmlContent, setHtmlContent] = useState("");
  const Agent = props.agent;
  const notify = (x) => toast(x);
  const notifyError = (x) => toast.error(x);

  useEffect(() => {
    if (body) {
      console.log("Raw body received:", body);

      try {
        // Parse the body if it's a JSON string
        const parsedBody = typeof body === "string" ? JSON.parse(body) : body;

        if (parsedBody && parsedBody.ops) {
          // If the body is in Delta format, convert it to HTML
          const tempContainer = document.createElement("div"); // Temporary container
          const tempQuill = new Quill(tempContainer);
          tempQuill.setContents(parsedBody); // Set the Delta content
          const convertedHTML = tempContainer.innerHTML; // Get the generated HTML
          console.log("Converted HTML:", convertedHTML);
          setHtmlContent(convertedHTML); // Update state with the HTML
        } else if (typeof body === "string") {
          console.warn("Body is raw HTML or plain text, rendering as-is.");
          setHtmlContent(body); // Assume raw HTML or plain text
        } else {
          console.error("Body format is unsupported:", body);
          setHtmlContent("Unable to render content.");
        }
      } catch (error) {
        console.error("Error processing body content:", error);
        setHtmlContent("Error rendering content.");
      }
    } else {
      console.warn("No body content provided.");
      setHtmlContent("No content available.");
    }
  }, [body]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let formData = { id: id };

    try {
      const response = await fetch(
        "https://us-central1-argon-fx-383518.cloudfunctions.net/deletePost",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        notify("Post deleted successfully!");
        console.log("Post deleted successfully!");
        navigate("/news");
      } else {
        const errorText = await response.text();
        console.error("Failed to delete post:", errorText);
        notifyError("Failed to delete the post.");
      }
    } catch (error) {
      console.error(error);
      notifyError("Error: " + error.message + " [SE-NEWS]");
    }
  };

  return (
    <Box
      className="m-5"
      display="grid"
      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
    >
      <Header
        title={title}
        subtitle="Posted by Vomtel"
        sx={{ gridColumn: "span 4" }}
      />
      <Box
        dangerouslySetInnerHTML={{ __html: htmlContent }}
        sx={{
          gridColumn: "span 4",
          "& h1": { fontSize: "26px", fontWeight: "bold" },
          "& h2": { fontSize: "22px", fontWeight: "bold" },
          "& h3": { fontSize: "18px", fontWeight: "bold" },
          "& p": { fontSize: "14px", lineHeight: "1.5" },
          "& a": { textDecoration: "underline" },
        }}
      ></Box>
      <Box
        sx={{ gridColumn: "span 4" }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="mb-0"
      >
        <Box></Box>
        {Agent !== undefined && Agent.Hierarchy === "Master" && (
          <Box>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginRight: "10px",
              }}
              component={Link}
              to="/editPost"
              state={{ title, body, id }}
            >
              <EditIcon sx={{ mr: "10px" }} />
              Edit
            </Button>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
              onClick={handleFormSubmit}
            >
              <DeleteIcon sx={{ mr: "10px" }} />
              Delete
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ShowPost;
