// import React from "react";
// import { useEffect, useState } from "react";
// import { Box, Button, useTheme } from "@mui/material";
// import Header from "../components/Header";
// import { tokens } from "../theme";
// import NoteAddIcon from "@mui/icons-material/NoteAdd";
// import { Link } from "react-router-dom";
// import Typography from "@mui/material/Typography";
// import VisibilityIcon from "@mui/icons-material/Visibility";

// const NewsViewAll = (props) => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const [data, setData] = useState([]);
//   const Agent = props.agent;

//   useEffect(() => {
//     fetchDataFromDatabase();
//   }, []);

//   const fetchDataFromDatabase = async () => {
//     await fetch(
//       "https://us-central1-argon-fx-383518.cloudfunctions.net/getAllBlogs"
//     )
//       .then((response) => response.json())
//       .then((data) => {
//         // Do something with the data, e.g. update component state
//         setData(data);
//         console.log(data);
//       })
//       .catch((error) => {
//         // Handle error
//         console.error(error);
//       });
//   };

//   return (
//     <Box
//       m="20px"
//       display={"grid"}
//       columnGap={"0px"}
//       rowGap={"15px"}
//       maxWidth={"70vw"}
//       gridTemplateColumns="repeat(4, minmax(0, 1fr))"
//     >
//       <Box
//         sx={{ gridColumn: "span 4" }}
//         display="flex"
//         justifyContent="space-between"
//         alignItems="center"
//         className="mb-0"
//       >
//         <Header title="News" subtitle="Vomtel & Spectrum news" />
//         <Box>
//           {Agent !== undefined && Agent.Hierarchy == "Master" && (
//             <Button
//               sx={{
//                 backgroundColor: colors.blueAccent[700],
//                 color: colors.grey[100],
//                 fontSize: "14px",
//                 fontWeight: "bold",
//                 padding: "10px 20px",
//               }}
//               component={Link}
//               to="/createPost"
//             >
//               <NoteAddIcon sx={{ mr: "10px" }} />
//               New post
//             </Button>
//           )}
//         </Box>
//       </Box>

//       {data.map((element) => {
//         return (
//           <Box
//             sx={{
//               gridColumn: "span 1",
//               backgroundImage: `url("${
//                 process.env.PUBLIC_URL +
//                 (element.img ? element.img : "assets/spectrum blank.jpg")
//               }")`,
//             }}
//             key={element.id}
//             width={"144px"}
//             height={"144px"}
//             position={"relative"}
//             marginBottom={"15px"}
//             justifySelf={"center"}
//             component={Link}
//             to={"/showPost"}
//             state={{ title: element.title, body: element.body, id: element.id }}
//           >
//             <Box className="m-2" position={"absolute"} bottom={"0"}>
//               <Typography color={colors.greenAccent[500]} variant="h5">
//                 {element.title}
//               </Typography>
//             </Box>
//           </Box>
//         );
//       })}
//     </Box>
//   );
// };

// export default NewsViewAll;

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  useTheme,
} from "@mui/material";
import Header from "../components/Header";
import { tokens } from "../theme";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { Link } from "react-router-dom";
import Quill from "quill";

const NewsViewAll = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const Agent = props.agent;

  useEffect(() => {
    fetchDataFromDatabase();
  }, []);

  const fetchDataFromDatabase = async () => {
    try {
      const response = await fetch(
        "https://us-central1-argon-fx-383518.cloudfunctions.net/getAllBlogs"
      );
      const fetchedData = await response.json();
      setData(fetchedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const parseBodyContent = (body) => {
    try {
      if (typeof body === "string") {
        const parsedBody = JSON.parse(body); // Parse as JSON
        if (parsedBody && parsedBody.ops) {
          const tempContainer = document.createElement("div");
          const tempQuill = new Quill(tempContainer);
          tempQuill.setContents(parsedBody); // Convert Delta to HTML
          return tempContainer.innerHTML; // Return HTML
        }
      }
      return body; // If not Delta, return plain text or HTML
    } catch (error) {
      console.error("Error parsing body content:", error);
      return "Content unavailable."; // Fallback for errors
    }
  };

  const truncateText = (text, maxLength) =>
    text.length > maxLength ? text.substring(0, maxLength) + "..." : text;

  const truncateBodyContent = (body, maxLength) => {
    const htmlContent = parseBodyContent(body); // Parse body content to HTML
    const plainText = new DOMParser().parseFromString(htmlContent, "text/html")
      .body.textContent; // Extract plain text
    return truncateText(plainText, maxLength); // Truncate plain text
  };

  return (
    <Box
      m="20px"
      display="grid"
      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
      columnGap="20px"
      rowGap="20px"
      maxWidth="70vw"
    >
      <Box
        sx={{ gridColumn: "span 4" }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="mb-0"
      >
        <Header title="All News" subtitle="View all Vomtel & Spectrum news" />
        <Box>
          {Agent !== undefined && Agent.Hierarchy === "Master" && (
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
              component={Link}
              to="/createPost"
            >
              <NoteAddIcon sx={{ mr: "10px" }} />
              New Post
            </Button>
          )}
        </Box>
      </Box>

      {data.map((element) => (
        <Card
          sx={{ maxWidth: 345 }}
          key={element.id}
          component={Link}
          to="/showPost"
          state={{ title: element.title, body: element.body, id: element.id }}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <CardMedia
            sx={{ height: 140 }}
            image={
              element.img
                ? process.env.PUBLIC_URL + element.img
                : process.env.PUBLIC_URL + "assets/spectrum blank.jpg"
            }
            title={element.title}
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              color={colors.primary[500]}
              fontWeight="fontWeightBold"
            >
              {truncateText(element.title, 20)}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "text.secondary" }}
              dangerouslySetInnerHTML={{
                __html: truncateBodyContent(element.body, 60),
              }}
            ></Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default NewsViewAll;
