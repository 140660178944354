import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { tokens } from "../theme";
import { Link } from "react-router-dom";
import Header from "../components/Header";

import BarChart from "../components/BarCharts/MOMBarChart";
import TUSCircle from "../components/Circles/TotalUnitSalesCircle";
import ChargebackCircle from "../components/Circles/ChargebackCircle";
import OrdersCanceledCircle from "../components/Circles/OrdersCanceledCircle";
import EnteredOrdersCircle from "../components/Circles/EnteredOrdersCircle";
import PendingCircle from "../components/Circles/PendingCircle";
import InternetPieChart from "../components/PieCharts/InternetPieChart";
import VideoPieChart from "../components/PieCharts/VideoPieChart";
import ChargebackTile from "../components/PercentageTiles/ChargebackTile";
import CancellationTile from "../components/PercentageTiles/CancellationTile";
import GigTile from "../components/PercentageTiles/GigTile";
import TotalSalesRanking from "../components/RankingBoxes/Team";
import AddIcon from "@mui/icons-material/Add";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import "../index.css";

const Dashboard = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Agent = props.agent;
  const [comms, setComms] = useState([]);
  const [commsEnabled, setCommsEnabled] = useState(false);
  const [period, setPeriod] = useState("all");
  const [data, setData] = useState({
    payout: [],
    chargeback: [],
    enteredOrders: [],
  });

  const handleChange = (event) => {
    setPeriod(event.target.value);
  };

  useEffect(() => {
    if (Agent && Agent.PartnerID) {
      console.log("Agentdash: ", Agent);
      fetchData();
      if (
        Agent.Hierarchy == "Master" ||
        Agent.Hierarchy == "Spectrum" ||
        Agent.Hierarchy == "Manager" ||
        Agent.Hierarchy == "Owner" ||
        Agent.Hierarchy == "Sub Distributor" ||
        Agent.Hierarchy == "Retention" ||
        Agent.Hierarchy == "Quality Assurance"
      ) {
        setCommsEnabled(true);
        fetchComms();
      }
    }
  }, [period, Agent]);

  // Consolidated Data Fetching Function
  const fetchData = async () => {
    const payload = { AgentID: Agent.AgentID };
    const urls = {
      payout:
        "https://us-central1-argon-fx-383518.cloudfunctions.net/getEmployeePayoutAggregated",
      chargeback:
        "https://us-central1-argon-fx-383518.cloudfunctions.net/getEmployeeChargebackAndOrdersAggregated",
      enteredOrders:
        "https://us-central1-argon-fx-383518.cloudfunctions.net/getEmployeeEnteredOrdersAggregated",
    };

    try {
      const responses = await Promise.all(
        Object.keys(urls).map((key) =>
          fetch(urls[key], {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }).then((response) => response.json())
        )
      );

      setData({
        payout: responses[0],
        chargeback: responses[1],
        enteredOrders: responses[2],
      });

      console.log("RESPONSES: ", responses);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetchComms = async () => {
    let url = "";
    if (Agent.Hierarchy == "Master" || Agent.Hierarchy == "Spectrum") {
      console.log("by mast");
      url =
        "https://us-central1-argon-fx-383518.cloudfunctions.net/getCommissionTotalsByPartnerID";
    } else if (
      Agent.Hierarchy == "Manager" ||
      Agent.Hierarchy == "Owner" ||
      Agent.Hierarchy == "Sub Distributor" ||
      Agent.Hierarchy == "Retention" ||
      Agent.Hierarchy == "Quality Assurance"
    ) {
      console.log("by pid");
      url =
        "https://us-central1-argon-fx-383518.cloudfunctions.net/getCommissionTotalsByPartnerID";
    } else if (Agent.Hierarchy == "Employee") {
      console.log("by agent");
      url = null;
    }

    let payload = { AgentID: Agent.AgentID, PartnerID: Agent.PartnerID };
    // let payload = { AgentID: "AGT00716", PartnerID: "VTL1169" };

    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        // Do something with the data, e.g. update component state
        setComms([]);
        setComms(data[0]);
        console.log("Comms data: ", data);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Header title="DASHBOARD" />
          <FormControl fullWidth sx={{ mt: "-25px", mb: "0px" }} size="small">
            <InputLabel id="demo-simple-select-label">Period</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={period}
              label="Period"
              onChange={handleChange}
              disabled
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"november"}>November</MenuItem>
              <MenuItem value={"december"}>December</MenuItem>
              <MenuItem value={"january"}>January</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[500],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            component={Link}
            to="/iframe"
          >
            <AddIcon sx={{ mr: "10px" }} />
            Enter Order
          </Button>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {commsEnabled ? (
          <Box
            gridColumn="span 12"
            display="grid"
            gridTemplateColumns="repeat(3, 1fr)"
            justifyItems={"center"}
            // justifySelf={"start"}
            paddingTop={"10px"}
            // border={"2px dotted blue"}
            backgroundColor={"#2d312e"}
          >
            <Box
              height={"120px"}
              padding={"5px"}
              justifyItems={"center"}
              // width={"170px"}
              backgroundColor={"#2d312e"}
            >
              <Typography
                color={colors.lightHeaders[100]}
                variant="h3"
                fontWeight="800"
              >
                COMMISSIONS
              </Typography>
              <Box
                style={{
                  fontFamily: "Bright Lights",
                  fontSize: "90px",
                  color: "rgb(69 197 86)",
                  marginTop: "-25px",
                }}
              >
                ${comms.CommTotal}
              </Box>
            </Box>
            <Box
              height={"120px"}
              // width={"170px"}
              padding={"5px"}
              justifyItems={"center"}
              backgroundColor={"#2d312e"}
            >
              {" "}
              <Typography
                color={colors.lightHeaders[100]}
                variant="h3"
                fontWeight="800"
              >
                PENDING
              </Typography>
              <Box
                style={{
                  fontFamily: "Bright Lights",
                  fontSize: "90px",
                  color: "#fbc544",
                  marginTop: "-25px",
                }}
              >
                ${comms.PendingTotal}
              </Box>
            </Box>
            <Box
              height={"120px"}
              // width={"170px"}
              padding={"5px"}
              justifyItems={"center"}
              backgroundColor={"#2d312e"}
            >
              {" "}
              <Typography
                color={colors.lightHeaders[100]}
                variant="h3"
                fontWeight="800"
              >
                CHARGEBACKS
              </Typography>
              <Box
                style={{
                  fontFamily: "Bright Lights",
                  fontSize: "90px",
                  color: "#e22839",
                  marginTop: "-25px",
                }}
              >
                ${comms.CBsTotal}
              </Box>
            </Box>
          </Box>
        ) : (
          ""
        )}
        {/* Task Manager */}
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            marginBottom="15px"
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography
              color={colors.lightHeaders[100]}
              variant="h5"
              fontWeight="600"
            >
              Task Manager
            </Typography>
          </Box>
          <Typography
            color={colors.lightHeaders[100]}
            variant="p"
            fontWeight="600"
            padding="25px"
            marginTop="10px"
          >
            Coming soon..
          </Typography>
        </Box>

        {/* ROW 1 - Circles Section */}
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          alignItems="center"
          justifyContent="center"
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap="0px"
          gridRow="span 3"
          overflow="hidden"
          color={"white"}
        >
          <TUSCircle agent={Agent} data={data.chargeback} period={period} />
          <ChargebackCircle
            agent={Agent}
            data={data.chargeback}
            period={period}
          />
          <OrdersCanceledCircle
            agent={Agent}
            data={data.chargeback}
            period={period}
          />
          <PendingCircle agent={Agent} data={data.chargeback} period={period} />
          <EnteredOrdersCircle
            agent={Agent}
            data={data.enteredOrders}
            period={period}
          />
        </Box>

        {/* ROW 2 - Tiles Section */}
        <Box
          gridColumn="span 4"
          gridRow="span 1"
          backgroundColor={colors.primary[400]}
          overflow="auto"
          display="grid"
          gridTemplateColumns="repeat(3, 1fr)"
        >
          <ChargebackTile
            agent={Agent}
            data={data.chargeback}
            period={period}
          />
          <CancellationTile
            agent={Agent}
            data={data.chargeback}
            period={period}
          />
          <GigTile agent={Agent} data={data.chargeback} period={period} />
        </Box>

        {/* ROW 3 - Rankings Section */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <TotalSalesRanking
            agent={Agent}
            data={data.chargeback}
            period={period}
            key={period}
          />
        </Box>

        {/* ROW 4 - Pie Charts Section */}
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
          alignItems="center"
          justifyContent="center"
          display="grid"
          gridTemplateColumns="repeat(4, 1fr)"
          color={colors.lightHeaders[100]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            gridColumn="span 4"
            marginTop="-30px"
          >
            Service breakdown
          </Typography>
          <Typography variant="h5" fontWeight="600" gridColumn="span 2">
            Internet
          </Typography>
          <Typography variant="h5" fontWeight="600" gridColumn="span 2">
            Video
          </Typography>
          <Box
            gridColumn="span 2"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="120px"
            width="100%"
            color={colors.lightHeaders[100]} // this method is new and does not work!
          >
            <InternetPieChart agent={Agent} data={data.chargeback} />
          </Box>
          <Box
            gridColumn="span 2"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="120px"
            width="100%"
            color={colors.lightHeaders[100]} // this method is new and does not work!
          >
            <VideoPieChart agent={Agent} data={data.chargeback} />
          </Box>
        </Box>

        {/* ROW 5 - Monthly Sales Trend Section */}
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          color={colors.lightHeaders[100]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Monthly Sales Trend
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart agent={Agent} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;

// import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
// import { useEffect, useState } from "react";
// import { tokens } from "../theme";
// import { Link } from "react-router-dom";
// import Header from "../components/Header";

// import MOMBarChart from "../components/BarCharts/MOMBarChart";
// import TUSCircle from "../components/Circles/TotalUnitSalesCircle";
// import ChargebackCircle from "../components/Circles/ChargebackCircle";
// import OrdersCanceledCircle from "../components/Circles/OrdersCanceledCircle";
// import EnteredOrdersCircle from "../components/Circles/EnteredOrdersCircle";
// import PendingCircle from "../components/Circles/PendingCircle";
// import InternetPieChart from "../components/PieCharts/InternetPieChart";
// import VideoPieChart from "../components/PieCharts/VideoPieChart";
// import ChargebackTile from "../components/PercentageTiles/ChargebackTile";
// import CancellationTile from "../components/PercentageTiles/CancellationTile";
// import GigTile from "../components/PercentageTiles/GigTile";
// import TotalSalesRanking from "../components/RankingBoxes/Team";
// import AddIcon from "@mui/icons-material/Add";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";

// const Dashboard = (props) => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const Agent = props.agent;

//   const [payout, setPayout] = useState([]);
//   const [chargeback, setChargeback] = useState([]);
//   const [enteredOrders, setEnteredOrders] = useState([]);
//   const [period, setPeriod] = useState("all");

//   const handleChange = (event) => {
//     let food = event.target.value;
//     setPeriod(food);
//     console.log("test dashboard: ", period);
//   };

//   useEffect(() => {
//     if (Agent && Agent.PartnerID) {
//       console.log("Agent: ", Agent);
//       fetchPayout();
//       fetchChargeback();
//       fetchEnteredOrders();
//     }
//   }, [period, Agent]);

//   // PAYOUT REPORT
//   const fetchPayout = async () => {
//     let url = "";
//     // let payload = { AgentID: Agent.AgentID };
//     let payload = { AgentID: "AGT00716" };

//     console.log("fetchDataFromDatabase: ", Agent.AgentID);

//     if (Agent.Hierarchy == "Master") {
//       url =
//         "https://us-central1-argon-fx-383518.cloudfunctions.net/getEmployeePayout";
//     } else {
//       url =
//         "https://us-central1-argon-fx-383518.cloudfunctions.net/getEmployeePayout";
//     }
//     await fetch(url, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(payload),
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         // Do something with the data, e.g. update component state
//         setPayout([]);
//         setPayout(data);
//         console.log("payout: ", data);
//       })
//       .catch((error) => {
//         // Handle error
//         console.error(error);
//       });
//   };

//   // CHARGEBACK REPORT
//   const fetchChargeback = async () => {
//     let url = "";
//     // let payload = { AgentID: Agent.AgentID };
//     let payload = { AgentID: "AGT00716" };

//     console.log("fetchDataFromDatabase: ", Agent.AgentID);

//     if (Agent.Hierarchy == "Master") {
//       url =
//         "https://us-central1-argon-fx-383518.cloudfunctions.net/getEmployeeChargeback";
//     } else {
//       url =
//         "https://us-central1-argon-fx-383518.cloudfunctions.net/getEmployeeChargeback";
//     }
//     await fetch(url, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(payload),
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         // Do something with the data, e.g. update component state
//         setChargeback([]);
//         setChargeback(data);
//         console.log("chargeback: ", data);
//       })
//       .catch((error) => {
//         // Handle error
//         console.error(error);
//       });
//   };

//   // ENTERED ORDERS REPORT
//   const fetchEnteredOrders = async () => {
//     let url = "";
//     // let payload = { AgentID: Agent.AgentID };
//     let payload = { AgentID: "AGT00716" };

//     console.log("fetchDataFromDatabase: ", Agent.AgentID);

//     if (Agent.Hierarchy == "Master") {
//       url =
//         "https://us-central1-argon-fx-383518.cloudfunctions.net/getEmployeeEnteredOrders";
//     } else {
//       url =
//         "https://us-central1-argon-fx-383518.cloudfunctions.net/getEmployeeEnteredOrders";
//     }
//     await fetch(url, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(payload),
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         // Do something with the data, e.g. update component state
//         setEnteredOrders([]);
//         setEnteredOrders(data);
//         console.log("EnteredOrders: ", data);
//       })
//       .catch((error) => {
//         // Handle error
//         console.error(error);
//       });
//   };

//   return (
//     <Box m="20px">
//       {/* HEADER */}
//       <Box display="flex" justifyContent="space-between" alignItems="center">
//         <Box>
//           <Header title="DASHBOARD" />
//           <FormControl fullWidth sx={{ mt: "-25px", mb: "0px" }} size="small">
//             <InputLabel id="demo-simple-select-label">Period</InputLabel>
//             <Select
//               labelId="demo-simple-select-label"
//               id="demo-simple-select"
//               value={period}
//               label="Age"
//               onChange={handleChange}
//             >
//               <MenuItem value={"all"}>All</MenuItem>
//               <MenuItem value={"november"}>November</MenuItem>
//               <MenuItem value={"december"}>December</MenuItem>
//               <MenuItem value={"january"}>January</MenuItem>
//             </Select>
//           </FormControl>
//         </Box>

//         <Box>
//           <Button
//             sx={{
//               backgroundColor: colors.blueAccent[500],
//               color: colors.grey[100],
//               fontSize: "14px",
//               fontWeight: "bold",
//               padding: "10px 20px",
//             }}
//             component={Link}
//             to="/iframe"
//           >
//             <AddIcon sx={{ mr: "10px" }} />
//             Enter Order
//           </Button>
//         </Box>
//       </Box>

//       {/* GRID & CHARTS */}
//       <Box
//         display="grid"
//         gridTemplateColumns="repeat(12, 1fr)"
//         gridAutoRows="140px"
//         gap="20px"
//       >
//         {/* Task Manager */}
//         <Box
//           gridColumn="span 4"
//           gridRow="span 3"
//           backgroundColor={colors.primary[400]}
//           overflow="auto"
//         >
//           <Box
//             display="flex"
//             justifyContent="space-between"
//             alignItems="center"
//             borderBottom={`4px solid ${colors.primary[500]}`}
//             marginBottom="15px"
//             colors={colors.grey[100]}
//             p="15px"
//           >
//             <Typography
//               color={colors.lightHeaders[100]}
//               variant="h5"
//               fontWeight="600"
//             >
//               Task Manager
//             </Typography>
//           </Box>
//           <Typography
//             color={colors.lightHeaders[100]}
//             variant="p"
//             fontWeight="600"
//             padding="25px"
//             marginTop="10px"
//           >
//             Coming soon..
//           </Typography>
//           {/* {mockTransactions.map((transaction, i) => (
//             <Box
//               key={`${transaction.txId}-${i}`}
//               display="flex"
//               justifyContent="space-between"
//               alignItems="center"
//               borderBottom={`4px solid ${colors.primary[500]}`}
//               p="15px"
//             >
//               <Box>
//                 <Typography
//                   color={colors.greenAccent[500]}
//                   variant="h5"
//                   fontWeight="600"
//                 >
//                   {transaction.txId}
//                 </Typography>
//                 <Typography color={colors.grey[100]}>
//                   {transaction.user}
//                 </Typography>
//               </Box>
//               <Box color={colors.grey[100]}>{transaction.date}</Box>
//               <Box
//                 backgroundColor={colors.greenAccent[500]}
//                 p="5px 10px"
//                 borderRadius="4px"
//               >
//                 ${transaction.cost}
//               </Box>
//             </Box>
//           ))} */}
//         </Box>
//         {/* ROW 1 */}
//         {/* super box start */}
//         <Box
//           gridColumn="span 4"
//           backgroundColor={colors.primary[400]}
//           alignItems="center"
//           justifyContent="center"
//           display="grid"
//           gridTemplateColumns="repeat(2, 1fr)"
//           gap="0px"
//           gridRow="span 3"
//           overflow="hidden"
//           color={"white"}
//         >
//           <Box
//             gridColumn="span 1"
//             backgroundColor={colors.primary[400]}
//             display="flex"
//             alignItems="center"
//             justifyContent="center"
//             height="120px"
//             width="100%"
//           >
//             <TUSCircle agent={Agent} data={payout} period={period} />
//           </Box>
//           <Box
//             gridColumn="span 1"
//             backgroundColor={colors.primary[400]}
//             display="flex"
//             alignItems="center"
//             justifyContent="center"
//             height="120px"
//             width="100%"
//           >
//             <ChargebackCircle agent={Agent} data={chargeback} period={period} />
//           </Box>
//           <Box
//             gridColumn="span 1"
//             backgroundColor={colors.primary[400]}
//             display="flex"
//             alignItems="center"
//             justifyContent="center"
//             height="120px"
//             width="100%"
//           >
//             <OrdersCanceledCircle
//               agent={Agent}
//               data={chargeback}
//               period={period}
//             />
//           </Box>
//           {/* <Box
//             gridColumn="span 1"
//             backgroundColor={colors.primary[400]}
//             display="flex"
//             alignItems="center"
//             justifyContent="center"
//             height="120px"
//             width="100%"
//           >
//             <PendingCircle agent={Agent} data={chargeback} />
//           </Box> */}
//           <Box
//             gridColumn="span 1"
//             backgroundColor={colors.primary[400]}
//             display="flex"
//             alignItems="center"
//             justifyContent="center"
//             height="120px"
//             width="100%"
//           >
//             <PendingCircle agent={Agent} data={chargeback} period={period} />
//           </Box>
//           <Box
//             gridColumn="span 1"
//             backgroundColor={colors.primary[400]}
//             display="flex"
//             alignItems="center"
//             justifyContent="center"
//             height="120px"
//             width="100%"
//           >
//             <EnteredOrdersCircle
//               agent={Agent}
//               data={enteredOrders}
//               period={period}
//             />
//           </Box>
//         </Box>

//         {/* ROW 2 */}

//         <Box
//           gridColumn="span 4"
//           gridRow="span 1"
//           backgroundColor={colors.primary[400]}
//           overflow="auto"
//           display="grid"
//           gridTemplateColumns="repeat(3, 1fr)"
//         >
//           <ChargebackTile agent={Agent} data={chargeback} period={period} />
//           <CancellationTile agent={Agent} data={chargeback} period={period} />
//           <GigTile agent={Agent} data={chargeback} period={period} />
//           {/* <ChargebackTile agent={Agent} data={payout} /> */}
//         </Box>

//         {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
//         {/* |||||||||||||||||||||||||RANKINGS||||||||||||||||||||||||| */}
//         {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}

//         <Box
//           gridColumn="span 4"
//           gridRow="span 2"
//           backgroundColor={colors.primary[400]}
//           overflow="auto"
//         >
//           {/* <Box
//             display="flex"
//             justifyContent="space-between"
//             alignItems="center"
//             borderBottom={`4px solid ${colors.primary[500]}`}
//             colors={colors.grey[100]}
//             p="15px"
//           >
//             <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
//               Total Sales Ranking
//             </Typography>
//           </Box> */}
//           <TotalSalesRanking
//             agent={Agent}
//             data={chargeback}
//             period={period}
//             key={period}
//           />
//         </Box>

//         {/* ROW 3 */}
//         <Box
//           gridColumn="span 6"
//           gridRow="span 2"
//           backgroundColor={colors.primary[400]}
//           p="30px"
//           alignItems="center"
//           justifyContent="center"
//           display="grid"
//           gridTemplateColumns="repeat(4, 1fr)"
//           color={colors.lightHeaders[100]}
//         >
//           <Typography
//             variant="h5"
//             fontWeight="600"
//             gridColumn="span 4"
//             marginTop="-30px"
//           >
//             Service breakdown
//           </Typography>
//           <Typography variant="h5" fontWeight="600" gridColumn="span 2">
//             Internet
//           </Typography>
//           <Typography variant="h5" fontWeight="600" gridColumn="span 2">
//             Video
//           </Typography>
//           {/* pie box */}
//           <Box
//             gridColumn="span 2"
//             backgroundColor={colors.primary[400]}
//             display="flex"
//             alignItems="center"
//             justifyContent="center"
//             height="120px"
//             width="100%"
//             color={colors.lightHeaders[100]} // this method is new and does not work!
//           >
//             <InternetPieChart
//               agent={Agent}
//               data={chargeback}
//               color={colors.lightHeaders[100]} // this method is new and does not work!
//             />
//           </Box>
//           <Box
//             gridColumn="span 2"
//             backgroundColor={colors.primary[400]}
//             display="flex"
//             alignItems="center"
//             justifyContent="center"
//             height="120px"
//             width="100%"
//           >
//             <VideoPieChart agent={Agent} data={chargeback} />
//           </Box>
//           {/* <Box
//             gridColumn="span 1"
//             backgroundColor={colors.primary[400]}
//             display="flex"
//             alignItems="center"
//             justifyContent="center"
//             height="120px"
//             width="100%"
//           >
//             <PieChart />
//           </Box> */}
//         </Box>
//         <Box
//           gridColumn="span 6"
//           gridRow="span 2"
//           backgroundColor={colors.primary[400]}
//           color={colors.lightHeaders[100]} // this method is new and does not work!
//         >
//           <Typography
//             variant="h5"
//             fontWeight="600"
//             sx={{ padding: "30px 30px 0 30px" }}
//           >
//             Monthly Sales Trend
//           </Typography>
//           <Box height="250px" mt="-20px">
//             <MOMBarChart
//               isDashboard={true}
//               agent={Agent}
//               payout={payout}
//               chargeback={chargeback}
//             />
//           </Box>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default Dashboard;
