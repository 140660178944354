// src/scenes/TeamExpViewComps/BarChart.jsx

import { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../../theme";

const BarChart = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [barData, setBarData] = useState([
    {
      week: 1,
      Internet: 0,
      Voice: 0,
      Video: 0,
      Mobile: 0,
    },
  ]);

  useEffect(() => {
    if (data) {
      FetchFromDatabase(data.AgentID);
    }
  }, [data]);

  // Fetch Partner Details
  const FetchFromDatabase = async () => {
    let Payload = { AgentID: data.AgentID };
    let url =
      "https://us-central1-argon-fx-383518.cloudfunctions.net/agentWeekoWeek";

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const Data = await response.json();

      if (!Data || Data.length === 0) {
        throw new Error("No data returned from the API");
      }

      // setData(Data);
      console.log("BAR DATA: ", Data);
      processData(Data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const processData = async (Data) => {
    // Assuming 'results' is the array of data you received from the query
    const formattedData = Data.map((data) => ({
      week: data.WeekStartDate,
      Internet: data.Internet,
      Voice: data.Voice,
      Video: data.Video,
      Mobile: 0, // Assuming Mobile data is not available, defaulting to 0
    }));

    console.log("formatted: ", formattedData);
    setBarData(formattedData);
  };

  return (
    <ResponsiveBar
      data={barData}
      theme={{
        axis: {
          domain: { line: { stroke: colors.primary[900] } },
          legend: { text: { fill: colors.primary[900] } },
          ticks: {
            line: { stroke: colors.primary[900], strokeWidth: 1 },
            text: { fill: colors.primary[900] },
          },
        },
        legends: { text: { fill: colors.primary[900] } },
      }}
      keys={["Internet", "Video", "Voice", "Mobile"]}
      indexBy="week"
      margin={{ top: 50, right: 130, bottom: 70, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "nivo" }}
      borderColor={{ from: "color", modifiers: [["darker", "1.6"]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Week",
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Sales",
        legendPosition: "middle",
        legendOffset: -40,
      }}
      enableLabel={true}
      labelSkipWidth={12}
      labelSkipHeight={0}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [{ on: "hover", style: { itemOpacity: 1 } }],
        },
      ]}
      role="application"
      barAriaLabel={({ id, value, indexValue }) =>
        `${id}: ${value} in week: ${indexValue}`
      }
    />
  );
};

export default BarChart;
