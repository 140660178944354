import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import { mockDataTeam } from "../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import { realData } from "../data/realData";
import { Link, useLocation } from "react-router-dom";
import Profile from "./QuickViewComponents/PartnerProfile";
import Sales from "./QuickViewComponents/Sales";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Agents from "./QuickViewComponents/Agents";

const Team = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState([]);
  const [period, setPeriod] = useState("all");
  const [partnerDetails, setPartnerDetails] = useState({});
  const [activeComponent, setActiveComponent] = useState("Company");

  let Agent = props.agent;
  // const PartnerObject = props.PartnerObject;

  const location = useLocation();
  const PartnerObject = location.state?.PartnerObject;
  const PartnerID = location.state?.PartnerID;

  useEffect(() => {
    console.log("PO: ", PartnerObject);
    if (Agent && Agent.PartnerID && data.length == 0) {
      //3344556677
      console.log("if statement", Agent.PartnerID);
      // Start call chain
      console.log("Partner Object PROP: ", PartnerObject);
      console.log("Partner Object PROP ID: ", PartnerID);
      PartnerDetails();
      console.log("PartnerDetails: ", partnerDetails.FirstName);
    }
  }, [Agent, PartnerObject, PartnerID]);

  // Fetch Partner Details
  const PartnerDetails = async () => {
    let Payload = { VTL: PartnerID };
    let url =
      "https://us-central1-argon-fx-383518.cloudfunctions.net/getPartnerDetailsByPartnerID";

    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(Payload),
    })
      .then((response) => response.json())
      .then((Data) => {
        setPartnerDetails(Data[0]);
        console.log("PartnerDetails: ", Data[0]);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };

  // Fetch Partner Aliases

  // Fetch all agents under the same VTL

  // Fetch all agents under the same VTL

  const handleChange = (event) => {
    let food = event.target.value;
    setPeriod(food);
    console.log("test dashboard: ", period);
  };

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="mb-0"
      >
        <Box>
          <Header
            color={colors.primary[500]}
            title={PartnerObject.PartnerName}
            subtitle={PartnerObject.PartnerID}
          />
          {/* <Typography
            color={colors.greenAccent[500]}
            variant="h5"
            fontWeight="600"
          >
            {PartnerObject.Mobile}
          </Typography> */}
        </Box>

        {/* <Header title="Status" subtitle={PartnerObject.Status} /> */}
        <Box>
          <Typography color="secondary" variant="h4" fontWeight="600">
            Status
          </Typography>
          <Typography color={colors.primary[900]} variant="h5" fontWeight="600">
            {/* {PartnerObject.Status} */} Active
          </Typography>
        </Box>
        {/* <Box>
          <FormControl fullWidth sx={{ mt: "-25px", mb: "0px" }} size="small">
            <InputLabel id="demo-simple-select-label">Period</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={period}
              label="Age"
              onChange={handleChange}
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"november"}>November</MenuItem>
              <MenuItem value={"december"}>December</MenuItem>
              <MenuItem value={"january"}>January</MenuItem>
            </Select>
          </FormControl>
        </Box> */}
      </Box>
      <Box
        m="10px 0 0px 0"
        display="grid"
        gridTemplateColumns="repeat(5, 1fr)"
        rowGap="15px"
        padding="15px"
        justifyContent={"center"}
        backgroundColor={colors.primary[100]}
        sx={{ boxShadow: 3 }}
        textAlign="center"
      >
        <Box>
          <Typography color={colors.primary[500]} variant="h5" fontWeight="600">
            Sales Trend
          </Typography>
          <Typography color={colors.primary[900]} variant="h5" fontWeight="600">
            {PartnerObject.SalesTrend + " %" || "--"}
          </Typography>
        </Box>
        <Box>
          <Typography color={colors.primary[500]} variant="h5" fontWeight="600">
            Chargebacks
          </Typography>
          <Typography color={colors.primary[900]} variant="h5" fontWeight="600">
            {PartnerObject.ChargebackPercentage + " %" || "--"}
          </Typography>
        </Box>
        <Box>
          <Typography color={colors.primary[500]} variant="h5" fontWeight="600">
            Cancelled
          </Typography>
          <Typography color={colors.primary[900]} variant="h5" fontWeight="600">
            {PartnerObject.Canceled + " %" || "--"}
          </Typography>
        </Box>
        <Box>
          <Typography color={colors.primary[500]} variant="h5" fontWeight="600">
            Gig
          </Typography>
          <Typography
            color={colors.greenAccent[900]}
            variant="h5"
            fontWeight="600"
          >
            {PartnerObject.GigPercentage + " %" || "--"}
          </Typography>
        </Box>
        <Box>
          <Typography color={colors.primary[500]} variant="h5" fontWeight="600">
            Video
          </Typography>
          <Typography color={colors.primary[900]} variant="h5" fontWeight="600">
            {PartnerObject.VideoPercentage + " %" || "--"}
          </Typography>
        </Box>
        <Box>
          <Typography color={colors.primary[500]} variant="h5" fontWeight="600">
            Mobile
          </Typography>
          <Typography color={colors.primary[900]} variant="h5" fontWeight="600">
            {PartnerObject.MobileSales + " %" || "--"}
          </Typography>
        </Box>
        <Box>
          <Typography color={colors.primary[500]} variant="h5" fontWeight="600">
            2nd Initial
          </Typography>
          <Typography color={colors.primary[900]} variant="h5" fontWeight="600">
            {PartnerObject.MobileSales + " %" || "--"}
          </Typography>
        </Box>{" "}
        <Box>
          <Typography color={colors.primary[500]} variant="h5" fontWeight="600">
            Duplicate #'s
          </Typography>
          <Typography color={colors.primary[900]} variant="h5" fontWeight="600">
            {PartnerObject.MobileSales + " %" || "--"}
          </Typography>
        </Box>
        <Box>
          <Typography color={colors.primary[500]} variant="h5" fontWeight="600">
            Market Comp
          </Typography>
          <Typography color={colors.primary[900]} variant="h5" fontWeight="600">
            {PartnerObject.MarketingCompliance || "--"}
          </Typography>
        </Box>
        <Box>
          <Typography color={colors.primary[500]} variant="h5" fontWeight="600">
            Call Calibration
          </Typography>
          <Typography color={colors.primary[900]} variant="h5" fontWeight="600">
            {PartnerObject.CallCalibrations || "--"}
          </Typography>
        </Box>
      </Box>
      <Box
        m="10px 0 0px 0"
        display="grid"
        gridTemplateColumns="repeat(5, 1fr)"
        rowGap="15px"
        columnGap="15px"
        padding="15px"
        justifyContent={"center"}
        textAlign="center"
      >
        <Button variant="contained" color="secondary" onClick={() => setActiveComponent("Company")}>
          Company Info
        </Button>
        <Button variant="contained" color="secondary" onClick={() => setActiveComponent("Agents")}>
          Agents
        </Button>
        <Button variant="contained" color="secondary" onClick={() => setActiveComponent("Sales")}>
          Sales
        </Button>
        <Button variant="contained" color="secondary" disabled>
          Call Calibrations
        </Button>
        <Button variant="contained" color="secondary" disabled>
          Marketing
        </Button>
      </Box>
      
      {activeComponent === "Company" ? (
        <Profile PartnerDetails={partnerDetails} PartnerObject={PartnerObject} />
      ) : activeComponent === "Agents" ? (
        <Agents PartnerDetails={partnerDetails} PartnerObject={PartnerObject} />
      ) : activeComponent === "Sales" ? (
        <Sales data={data} />
      ) : <Profile PartnerDetails={partnerDetails} PartnerObject={PartnerObject} />}
    </Box>
  );
};

export default Team;
