import { useEffect, useState } from "react";
import { tokens } from "../../theme";
import { Box, Typography, useTheme } from "@mui/material";

const Circle = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { agent, data } = props;

  const [internet, setInternet] = useState(0);
  const [video, setVideo] = useState(0);
  const [voice, setVoice] = useState(0);
  const [mobile, setMobile] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    console.log("Agent:", agent);
    console.log("Data:", data);

    if (
      agent &&
      agent.AgentID &&
      data &&
      String(data.Agent).toLowerCase() === String(agent.AgentID).toLowerCase()
    ) {
      console.log("Agent ID DID MATCH TUS");

      // Set individual unit sales counts
      const internetSales = data.InternetSales || 0;
      const videoSales = data.VideoSales || 0;
      const voiceSales = data.VoiceSales || 0;
      const mobileSales = data.MobileSales || 0;

      setInternet(internetSales);
      setVideo(videoSales);
      setVoice(voiceSales);
      setMobile(mobileSales);

      // Calculate the total units
      const totalUnits = internetSales + videoSales + voiceSales + mobileSales;
      setTotal(totalUnits);
    } else {
      console.error("Agent ID or Data is not matching. TUS");
    }
  }, [data, agent]);

  return (
    <Box
      width="100%"
      display="grid"
      gridTemplateColumns="repeat(2, 1fr)"
      padding="5px"
    >
      <Box gridColumn="span 2">
        <Typography variant="h5" fontWeight="600">
          Total unit sales
        </Typography>
      </Box>
      <Box borderLeft="1px dashed #4cceac" paddingLeft="6px">
        <Box>Internet: {internet}</Box>
        <Box>Video: {video}</Box>
        <Box>Voice: {voice}</Box>
        <Box>Mobile: {mobile}</Box>
      </Box>
      <Box
        borderRadius="50px"
        height="85px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          color={colors.lightHeaders[100]}
          variant="h1"
          fontWeight="600"
        >
          {total}
        </Typography>
      </Box>
    </Box>
  );
};

export default Circle;
