import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Agents = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);

  const Agent = props.agent;
  const PartnerObject = props.PartnerObject;
  const PartnerDetails = props.PartnerDetails;

  useEffect(() => {
    if (Agent && Agent.PartnerID && data.length == 0) {
      console.log("if statement inner", Agent.PartnerID);
      console.log("Partner Object inner: ", PartnerObject.PartnerID);
      console.log("PartnerDetails inner: ", PartnerDetails);
      fetchAgentVTLs();
    }
  }, [Agent, PartnerObject]);

  const fetchAgentVTLs = async () => {
    let url = "";
    let AgentIDs = [];
    let AgentObjects = [];

    // let VTL = { VTL: "VTL1295" };
    let VTL = { VTL: PartnerObject.PartnerID };

    url =
      "https://us-central1-argon-fx-383518.cloudfunctions.net/getAgentsBySpectrumVTL";

    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(VTL),
    })
      .then((response) => response.json())
      .then((Data) => {
        // Do something with the data, e.g. update component state
        setData([]);
        setData(Data);

        // Create Agent Objects for each agent
        Data.forEach((item) => {
          AgentIDs.push(item.AgentID);

          AgentObjects.push({
            AgentID: item.AgentID,
            FirstName: item.FirstName,
            Internet: 0,
            Video: 0,
            Mobile: 0,
            Voice: 0,
            Chargebacks: 0,
            TotalUnits: 0,
            Canceled: 0,
            All: 0,
          });
        });
        console.log("Agent Object 1:", AgentObjects);
        // fetchChargebacksByMultipleVTLs(AgentIDs, AgentObjects);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };

  const columns = [
    {
      field: "AgentName",
      headerName: "Agent",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { AgentName, AgentID } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Link
            to="/TeamExpView"
            state={{
              AgentID: AgentID,
            }}
          >
            {AgentName}
          </Link>
        );
        return jsxBlock;
      },
    },
    {
      field: "SalesTrend",
      headerName: "Sales Trend",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { SalesTrend } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              SalesTrend > 9
                ? "#5dc579"
                : SalesTrend < -81
                ? "#b3605e"
                : "#e3b81b"
            }
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "ChargebackPercentage",
      headerName: "Chargebacks",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { ChargebackPercentage } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              ChargebackPercentage > 24
                ? "#b3605e"
                : ChargebackPercentage < 16
                ? "#5dc579"
                : "#e3b81b"
            }
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "SalesCanceledPercentage",
      headerName: "Canceled",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { SalesCanceledPercentage } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              SalesCanceledPercentage > 14.99
                ? "#b3605e"
                : SalesCanceledPercentage < 7
                ? "#5dc579"
                : "#e3b81b"
            }
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "GigPercentage",
      headerName: "Gig",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { GigPercentage } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={GigPercentage > 18 ? "#b3605e" : "#5dc579"}
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "VideoePercentage",
      headerName: "Video",
      flex: 1,
      renderCell: ({ row: { VideoePercentage } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={VideoePercentage > 0.1 ? "#b3605e" : "#5dc579"}
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "INCOMEPLETE",
      headerName: "Mobile",
      flex: 1,
      renderCell: ({ row: {} }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={"#5dc579"}
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "INCOMEPLETEx",
      headerName: "2nd Init",
      flex: 1,
      renderCell: ({ row: {} }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={"#5dc579"}
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "INCOMEPLETExx",
      headerName: "Dupl #s",
      flex: 1,
      renderCell: ({ row: {} }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={"#5dc579"}
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "INCOMEPLETExxx",
      headerName: "2nd Init",
      flex: 1,
      renderCell: ({ row: {} }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={"#5dc579"}
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "INCOMEPLETExxxx",
      headerName: "Call Calibr",
      flex: 1,
      renderCell: ({ row: {} }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={"#5dc579"}
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "TrainingComplete",
      headerName: "Training",
      flex: 1,
      renderCell: ({ row: { TrainingComplete } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={TrainingComplete == "Yes" ? "#5dc579" : "#b3605e"}
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
    {
      field: "Status",
      headerName: "Status",
      flex: 1,
      renderCell: ({ row: { Status } }) => {
        let jsxBlock = null;
        jsxBlock = (
          <Box
            width="30px"
            height="30px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              Status == "Active"
                ? "#5dc579"
                : Status == "Disabled"
                ? "#b3605e"
                : "#e3b81b"
            }
            borderRadius="25px"
          ></Box>
        );
        return jsxBlock;
      },
    },
  ];

  return (
    <Box
      m="10px 0 40px 0"
      display="grid"
      gridTemplateColumns="repeat(1, 1fr)"
      rowGap="15px"
      columnGap="15px"
      justifyContent={"center"}
    >
      <Box
        m="10px 0 40px 0"
        height="75vh"
        sx={{
          "& .MuiTablePagination-root": {
            color: colors.lightHeaders[100],
          },
          "& .MuiDataGrid-root": {
            border: "none",
            color: colors.lightHeaders[100],
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.lightHeaders[100],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
            color: colors.lightHeaders[100],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          getRowId={(row) => row.AgentID}
        />
      </Box>
    </Box>
  );
};

export default Agents;
