import React from "react";
import Header from "../components/Header";
import { Box, Button, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { tokens } from "../theme";
import { Link } from "react-router-dom";

const Portals = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Agent = props.agent;
  if (Agent) {
    if (
      Agent.Hierarchy == "Master" ||
      Agent.Hierarchy == "Owner" ||
      Agent.Hierarchy == "SubMaster"
    ) {
      return (
        <Box
          m="20px"
          display={"grid"}
          columnGap={"30px"}
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        >
          <Box sx={{ gridColumn: "span 4" }}>
            <Header title="Portals" subtitle="Activation portals" />
          </Box>
          <Box
            sx={{ gridColumn: "span 2" }}
            display={"grid"}
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            rowGap={"15px"}
            textAlign={"center"}
          >
            <Box sx={{ gridColumn: "span 2" }}>
              <Button
                sx={{ width: "205px" }}
                variant="contained"
                color="secondary"
                component={Link}
                to="/iframe"
              >
                Vomtel Sales Order Portal
              </Button>
            </Box>
            <Box sx={{ gridColumn: "span 2" }}>
              <Button
                sx={{ width: "205px" }}
                variant="contained"
                color="secondary"
                component={Link}
                to="/partnerRegisterSale"
              >
                Partner Register Sale
              </Button>
            </Box>
            {/* <Box sx={{ gridColumn: "span 2" }}>
              <Button
                sx={{ width: "205px" }}
                variant="contained"
                color="secondary"
                href="https://retailportal.spectrum.com/Account/Login.aspx?ReturnUrl=%2f"
                target="_Blank"
              >
                Spectrum Sales Order Portal
              </Button>
            </Box> */}
            {/* <Box sx={{ gridColumn: "span 2" }}>
              <Button
                sx={{ width: "205px" }}
                variant="contained"
                color="secondary"
                component={Link}
                to="/mobileOrderEntry"
              >
                Mobile Order Entry
              </Button>
            </Box> */}
            <Box sx={{ gridColumn: "span 2" }}>
              <Button
                sx={{ width: "205px" }}
                variant="contained"
                color="secondary"
                href="https://directsales.spectrum.com/mobile-token-generator/?SYS=UB"
                target="_blank"
              >
                Mobile Token Generator
              </Button>
            </Box>
            {/* <Box sx={{ gridColumn: "span 2" }}>
              <Button
                sx={{ width: "205px" }}
                variant="contained"
                color="secondary"
                href="https://vomtel.shop"
                target="_blank"
              >
                Vomtel Shop
              </Button>
            </Box> */}
            <Box sx={{ gridColumn: "span 2" }}>
              <Button
                sx={{ width: "205px" }}
                variant="contained"
                color="secondary"
                component={Link}
                to="/agentRegistration"
              >
                Agent Registration
              </Button>
            </Box>
            <Box sx={{ gridColumn: "span 2" }}>
              <Button
                sx={{ width: "205px" }}
                variant="contained"
                color="secondary"
                component={Link}
                to="/submitAppeal"
              >
                Submit Appeal
              </Button>
            </Box>
            <Box sx={{ gridColumn: "span 2" }}>
              <Button
                sx={{ width: "205px" }}
                variant="contained"
                color="secondary"
                component={Link}
                to="/eventForm"
              >
                Events Aproval
              </Button>
            </Box>
            <Box sx={{ gridColumn: "span 2" }}>
              <Button
                sx={{ width: "205px" }}
                variant="contained"
                color="secondary"
                component={Link}
                to="/adApproval"
              >
                Ad Approval
              </Button>
            </Box>
            <Box sx={{ gridColumn: "span 2" }}>
              <Button
                sx={{ width: "205px" }}
                variant="contained"
                color="secondary"
                component={Link}
                to="/SpectrumMDP"
              >
                Spect Mobile Device Pricing
              </Button>
            </Box>
            <Box sx={{ gridColumn: "span 2" }}>
              <Button
                sx={{ width: "205px" }}
                variant="contained"
                color="secondary"
                href="https://bcl.spectrum.com/"
                target="_blank"
              >
                Broad Band Labels
              </Button>
            </Box>
          </Box>
        </Box>
      );
    } else if (Agent.Hierarchy == "Employee" || "Retention") {
      return (
        <Box
          m="20px"
          display={"grid"}
          columnGap={"30px"}
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        >
          <Box sx={{ gridColumn: "span 4" }}>
            <Header title="Portals" subtitle="Activation portals" />
          </Box>
          <Box
            sx={{ gridColumn: "span 2" }}
            display={"grid"}
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            rowGap={"15px"}
            textAlign={"center"}
          >
            <Box sx={{ gridColumn: "span 2" }}>
              <Button
                sx={{ width: "205px" }}
                variant="contained"
                color="secondary"
                component={Link}
                to="/iframe"
              >
                Vomtel Sales Order Portal
              </Button>
            </Box>
            <Box sx={{ gridColumn: "span 2" }}>
              <Button
                sx={{ width: "205px" }}
                variant="contained"
                color="secondary"
                component={Link}
                to="/partnerRegisterSale"
              >
                Partner Register Sale
              </Button>
            </Box>
            {/* <Box sx={{ gridColumn: "span 2" }}>
              <Button
                sx={{ width: "205px" }}
                variant="contained"
                color="secondary"
                href="https://retailportal.spectrum.com/Account/Login.aspx?ReturnUrl=%2f"
                target="_Blank"
              >
                Spectrum Sales Order Portal
              </Button>
            </Box> */}

            {/* <Box sx={{ gridColumn: "span 2" }}>
              <Button
                sx={{ width: "205px" }}
                variant="contained"
                color="secondary"
                component={Link}
                to="/mobileOrderEntry"
              >
                Mobile Order Entry
              </Button>
            </Box> */}
            {/* friday friday friday friday friday friday friday friday friday friday friday friday friday friday friday friday*/}
            <Box sx={{ gridColumn: "span 2" }}>
              <Button
                sx={{ width: "205px" }}
                variant="contained"
                color="secondary"
                href="https://directsales.spectrum.com/mobile-token-generator/?SYS=UB"
                target="_blank"
              >
                Mobile Token Generator
              </Button>
            </Box>
            <Box sx={{ gridColumn: "span 2" }}>
              <Button
                sx={{ width: "205px" }}
                variant="contained"
                color="secondary"
                component={Link}
                to="/SpectrumMDP"
              >
                Spect Mobile Device Pricing
              </Button>
            </Box>
            <Box sx={{ gridColumn: "span 2" }}>
              <Button
                sx={{ width: "205px" }}
                variant="contained"
                color="secondary"
                href="https://bcl.spectrum.net/"
                target="_blank"
              >
                Broad Band Labels
              </Button>
            </Box>
          </Box>
        </Box>
      );
    } else if (Agent.Hierarchy == undefined) {
      alert(
        "Your account does not have a hierarchy assigned please reach out to your account manager to fix this."
      );
    }
  }
};

export default Portals;
