import React, { useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CycloneIcon from "@mui/icons-material/Cyclone";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import PhoneIcon from "@mui/icons-material/Phone";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import StorefrontIcon from "@mui/icons-material/Storefront";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.lightHeaders[100] }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = (props) => {
  const { agent } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const [accessibleItems, setAccessibleItems] = useState([]);

  const Access = {
    Master: [
      "Dashboard",
      "Admin",
      "Training",
      "News",
      "Team",
      "FAQ",
      "Marketing",
      "Portals",
      "Numbers",
      "Reports",
      "Upload Data",
      "SQV",
      "AQV",
      "DQV",
      "TQV",
      // "STV",
      // "ATV",
      // "DTV",
    ],
    "Sub Distributor": [
      "Dashboard",
      "Training",
      "News",
      "Team",
      "FAQ",
      "Marketing",
      "Portals",
      "Numbers",
      "Reports",
    ],
    Owner: [
      "Dashboard",
      "Training",
      "News",
      "Team",
      "FAQ",
      "Marketing",
      "Portals",
      "Numbers",
      "Reports",
      "TQV",
    ],
    Manager: [
      "Dashboard",
      "Training",
      "News",
      "Team",
      "FAQ",
      "Marketing",
      "Portals",
      "Numbers",
      "Reports",
      "TQV",
    ],
    Employee: [
      "Dashboard",
      "Training",
      "News",
      "FAQ",
      "Marketing",
      "Portals",
      "Numbers",
      "Reports",
    ],
    Marketing: [
      "Dashboard",
      "Training",
      "News",
      "FAQ",
      "Marketing",
      "Numbers",
      "Reports",
    ],
    Retention: [
      "Dashboard",
      "Training",
      "News",
      "FAQ",
      "Marketing",
      "Numbers",
      "Reports",
    ],
    "Quality Assurance": [
      "Dashboard",
      "Training",
      "News",
      "FAQ",
      "Marketing",
      "Numbers",
      "Reports",
    ],
    Spectrum: [
      "Dashboard",
      "Training",
      "News",
      "FAQ",
      "Marketing",
      "Numbers",
      "Reports",
      "SQV",
    ],
    "Direct Tv": [
      "Dashboard",
      "Training",
      "News",
      "FAQ",
      "Marketing",
      "Numbers",
      "Reports",
      "DQV",
    ],
    ATT: [
      "Dashboard",
      "Training",
      "News",
      "FAQ",
      "Marketing",
      "Numbers",
      "Reports",
      "AQV",
    ],
    None: ["FAQ"],
  };

  useEffect(() => {
    if (agent && agent.Hierarchy) {
      let items = Access[agent.Hierarchy] || [];
      if (agent.Status === "Limited") {
        if (["Owner", "Manager"].includes(agent.Hierarchy)) {
          items = ["Training", "FAQ", "Team"];
        } else {
          items = ["Training", "FAQ"];
        }
      }
      setAccessibleItems(items);
    } else {
      let items = Access["None"] || [];
      setAccessibleItems(items);
    }
  }, [agent]);

  const menuItems = {
    Dashboard: { title: "Dashboard", to: "/", icon: <HomeOutlinedIcon /> },
    Admin: {
      title: "Admin",
      to: "/vomteladmin",
      icon: <AdminPanelSettingsOutlinedIcon />,
    },
    Training: {
      title: "Training",
      to: "/training",
      icon: <FitnessCenterIcon />,
    },
    News: { title: "News", to: "/news", icon: <NewspaperIcon /> },
    Team: { title: "Manage Team", to: "/team", icon: <PeopleOutlinedIcon /> },
    FAQ: { title: "FAQ Page", to: "/faq", icon: <HelpOutlineOutlinedIcon /> },
    Marketing: {
      title: "Online Marketing",
      to: "/onlineMarketing",
      icon: <StorefrontIcon />,
    },
    Portals: { title: "Portals", to: "/portals", icon: <CycloneIcon /> },
    Numbers: { title: "Numbers", to: "/numbers", icon: <PhoneIcon /> },
    Reports: {
      title: "Reports",
      to: "/ReportsLanding",
      icon: <ReceiptOutlinedIcon />,
    },
    "Upload Data": {
      title: "Upload Data",
      to: "/dataUpload",
      icon: <CloudUploadIcon />,
    },
    SQV: {
      title: "Spectrum Quick View",
      to: "/spectrumquickview",
      icon: <VisibilityIcon />,
    },
    AQV: {
      title: "AT&T Quick View",
      to: "/attquickview",
      icon: <VisibilityIcon />,
    },
    DQV: {
      title: "Direct TV Quick View",
      to: "/directtvquickview",
      icon: <VisibilityIcon />,
    },
    TQV: {
      title: "Team Quick View",
      to: "/teamQuickView",
      icon: <PeopleOutlinedIcon />,
    },
    STV: {
      title: "Spect Team View",
      to: "/teamQuickView",
      icon: <PeopleOutlinedIcon />,
    },
    ATV: {
      title: "ATT Team View",
      to: "/teamQuickView",
      icon: <PeopleOutlinedIcon />,
    },
    DTV: {
      title: "DTV Team View",
      to: "/teamQuickView",
      icon: <PeopleOutlinedIcon />,
    },
  };

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
          height: "120%",
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#71f7ff !important",
        },
        "& .pro-menu-item.active": {
          color: "#71f7ff !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            color={colors.lightHeaders[100]}
            style={{
              margin: "10px 0 20px 0",
              color: colors.lightHeaders[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Link to={"/"}>
                  <img
                    src={process.env.PUBLIC_URL + "assets/logo.webp"}
                    alt="Logo"
                  />
                </Link>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {/* User profile on sidebar */}
          {!isCollapsed && agent && (
            <Box
              mb="25px"
              borderTop="2px dotted grey"
              borderBottom="2px dotted grey"
              paddingBottom="15px"
              mt="-7px"
            >
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.lightHeaders[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {agent.FirstName}
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[700]}>
                  {agent.Hierarchy}
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.lightHeaders[100]}
                  mb="-5px"
                >
                  Spectrum Sales ID: {agent.SalesID}
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.lightHeaders[100]}
                  mb="-5px"
                >
                  Partner ID: {agent.PartnerID}
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.lightHeaders[100]}
                  mb="-10px"
                >
                  Agent ID: {agent.AgentID}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            {accessibleItems.map((item) => (
              <Item
                key={item}
                title={menuItems[item].title}
                to={menuItems[item].to}
                icon={menuItems[item].icon}
                selected={selected}
                setSelected={setSelected}
              />
            ))}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
